import { SsidChart as LineChartIcon, CalculateOutlined as CalculateIcon } from '@mui/icons-material';
import { Box, Card, CardContent, CardHeader, Divider, Grid, IconButton, Typography } from '@mui/material';
import dayjs from 'dayjs';
import React, { FC, useMemo, useState } from 'react';
import { ChartProps, elevation } from './DashboardWidget';
import { useUserPreference } from './UserPreferences';
import useSummary from './use-summary';
import { PatientActivityDatum } from './DashboardPatientActivity';
import { ReviewTrendDatum } from './DashboardReviewTrend';
import useColorPalettes from './use-color-palettes';
import { SummitMessagingCosts } from './DashboardMessagingCost';
import { useParentOrg } from './Organizations';
import { RecoverableRevenueTrendDatum } from './DashboardRecoverableRevenue';
import { DashboardInfo } from './DashboardInfo';

// temp placeholders for org settings
const frontDeskHourlyWage = 30;
const fteHoursPerMonth = 160;
const adImpressionCPM = 50;
const fiveStarReviewValue = 200;
const manualMessageMinutes = 3;
const manualAmenityMinutes = 10;
const monthlyCost = 850;
const appointmentsToAmenitiesFactor = 0.04; // approx rate of amenity site visits to appointments, for use when amenity count wasn't tracked
const appointmentsToProfilesFactor = 0.9; // approx rate of profiles to appointments, for use when profile count wasn't tracked

export const formatCurrency = ( v: number, opts?: Intl.NumberFormatOptions ) => {
  return v.toLocaleString( undefined, {
    style: 'currency',
    currency: 'USD',
    trailingZeroDisplay: 'stripIfInteger',
    notation: v >= 100_000 ? 'compact' : 'standard',
    ...opts,
  } );
};

export const formatNumber = ( v: number, opts?: Intl.NumberFormatOptions ) => {
  return v.toLocaleString( undefined, {
    style: 'decimal',
    trailingZeroDisplay: 'stripIfInteger',
    notation: v >= 100_000 ? 'compact' : 'standard',
    maximumFractionDigits: 1,
    ...opts,
  } );
};

interface LineItemProps {
  value: number;
  operation?: 'add' | 'subtract' | 'multiply' | 'divide';
  currency?: boolean;
  label?: string;
  formatOpts?: Intl.NumberFormatOptions;
}
const LineItem: FC<LineItemProps> = props => {
  const { value, currency, label, formatOpts } = props;
  const formattedValue = useMemo( () => {
    if( currency ) return formatCurrency( value, formatOpts );
    return formatNumber( value, formatOpts );
  }, [ value, currency ] );
  const operation = useMemo( () => {
    if( props.operation == 'add' ) return '+';
    if( props.operation == 'subtract' ) return '−';
    if( props.operation == 'multiply' ) return '×';
    if( props.operation == 'divide' ) return '÷';
  }, [ props.operation ] );
  if( !operation ) return <Typography fontSize='inherit'>{ formattedValue } { label ?? '' }</Typography>;
  return (
    <Grid container justifyContent='space-between'>
      <Typography display='inline-block' marginX={ 1 } fontSize='inherit'>{ operation }</Typography>
      <Typography display='inline-block' fontSize='inherit'>{ formattedValue } { label ?? '' }</Typography>
    </Grid>
  );
};

interface ValueDeliveredCardProps {
  value: number;
  label: string;
  days?: number;
  loss?: boolean;
  largeFontSize?: number;
  smallFontSize?: number;
}
const ValueDeliveredCard: FC<ValueDeliveredCardProps> = props => {
  const { value, label, days, loss } = props;

  const colorPalettes = useColorPalettes();
  const [ green ] = useMemo( () => colorPalettes.spectrumGreenRed, [ colorPalettes ] );
  const [ red ] = useMemo( () => colorPalettes.spectrumGreenRed.reverse(), [ colorPalettes ] );

  return (
    <Box
      marginBottom={ 2 }
      marginLeft={ 1 }

    >
      <Typography
        fontSize={ props.largeFontSize ?? 84 }
        fontWeight='bold'
        color={ value > 0 ? ( loss ? red : green ) : undefined }
        sx={ { textAlign: 'right' } }
      >
        { formatCurrency( Math.round( value ) ) }
      </Typography>
      <Typography
        fontSize={ props.smallFontSize ?? 24 }
        sx={ { textAlign: 'right' }}
      >
        { days ? `Annualized: ${ formatCurrency( Math.round( value * 365 / days ) ) }` : ' ' }
      </Typography>
      <Typography
        fontSize={ 18 }
        fontWeight='medium'
      >
        { label } { loss ? 'Loss' : 'Value' }
      </Typography>
    </Box>
  );
};

export const DashboardAdvertisingValueDelivered: FC<ChartProps> = ( { date } ) => {
  const defaultDurationDays = 30;
  const { preferences, isLoading: isLoadingPreferences } = useUserPreference();
  const [ showDetails, setShowDetails ] = useState( false );

  const days = useMemo( () => {
    if( isLoadingPreferences || !preferences ) return defaultDurationDays;
    return preferences.dashboardActivityDays as number || defaultDurationDays;
  }, [ preferences, isLoadingPreferences ] );

  const summary = useSummary<PatientActivityDatum[]>( 'patientActivity', { date, days } );
  const { messageCount, amenityCount, appointmentCount, isEstimatedAmenityCount } = useMemo( () => {
    const data = [ 'Messages', 'Amenities', 'Appointments' ].map( id => ( {
      id, data: summary.data?.map( d => {
        const { ymd, md, date } = d;
        const y = d[ id ] >= 1 ? d[ id ] : null; // no zeros for log scale
        return { x: ymd, y, ymd, md, date };
      } )
        .filter( d => !!d.y )
        .sort( ( a, b ) => a.ymd < b.ymd ? -1 : 1 ) || []
    } ) );
    const counts = Object.fromEntries( ( data || [] ).map( ( { id, data = [] } ) => [ id, data.reduce( ( tot, d ) => ( d.y || 0 ) + tot, 0 ) ] ) );
    const appointmentCount = counts.Appointments || 0;
    return {
      appointmentCount: appointmentCount,
      amenityCount: counts.Amenities || appointmentCount * appointmentsToAmenitiesFactor,
      isEstimatedAmenityCount: !counts.Amenities,
      messageCount: counts.Messages || 0,
    };
  }, [ summary, appointmentsToAmenitiesFactor ] );

  return (
    <Card
      elevation={ elevation }
      sx={ {
        minHeight: '25em',
        height: '100%',
        overflowX: 'visible',
        overflow: 'initial',
      } }
    >
      <CardHeader
        title={
          <Box sx={ { display: 'flex' } } >
            <Typography>Brand Impression Value Delivered</Typography>
            <DashboardInfo tag='brand-impression-val'>
              <i>Brand Impression Value Delivered</i> shows the marketing value received for messages sent and patients using the amenities site.
              <br/>Use the <CalculateIcon fontSize='small' sx={{ verticalAlign: 'bottom' }}/> Calculations button to view the data and math that make up these values.
            </DashboardInfo>
            <Box sx={ { flexGrow: 1 } } />
            <IconButton
              sx={ {
                // paddingTop: 0, paddingBottom: 0,
                marginTop: '-8px',
                marginBottom: '-8px',
              } }
              onClick={ () => setShowDetails( !showDetails ) }
            >
              { showDetails ? <LineChartIcon /> : <CalculateIcon /> }
            </IconButton>
          </Box>
        }
        titleTypographyProps={ {
          sx: {
            fontSize: '1.2rem',
            fontWeight: 400,
            // lineHeight: 1.334,
          }
        } }
        subheader={ `${ days } Days Ending ${ ( date ?? dayjs() ).format( 'MMM D, YYYY' ) }` }
        subheaderTypographyProps={ {
          sx: {
            fontSize: '0.9rem',
          }
        } }
      />
      <CardContent
        sx={ {
          overflowX: 'visible',
        } }
      >
        {
          showDetails ?
            <Box>
              <Grid container justifyContent='space-around' alignItems='end'>
                <Grid direction='column' justifyItems='end' margin={ 3 }>
                  <LineItem value={ messageCount } label='messages' />
                  <LineItem operation='multiply' currency value={ adImpressionCPM } label='impression CPM' />
                  <LineItem operation='divide' value={ 1000 } />
                  <Divider sx={{ width: '100%' }}/>
                  <LineItem currency value={ messageCount * adImpressionCPM / 1000 } label='messaging value'/>
                </Grid>
                <Grid item flexGrow={ 1 } />
              </Grid>
              <Grid container justifyContent='space-around' alignItems='end'>
                <Grid direction='column' justifyItems='end' margin={ 3 }>
                  { isEstimatedAmenityCount ?
                    <>
                      <LineItem value={ appointmentCount } label='appointments' />
                      <LineItem operation='multiply' value={ appointmentsToAmenitiesFactor } label='avg site visits per appt' formatOpts={{ maximumFractionDigits: 2 }} />
                    </> :
                    <LineItem value={ amenityCount } label='amenity site visits' />
                  }
                  <LineItem operation='multiply' currency value={ adImpressionCPM } label='impression CPM' />
                  <LineItem operation='divide' value={ 1000 } />
                  <Divider sx={{ width: '100%' }}/>
                  <LineItem currency value={ amenityCount * adImpressionCPM / 1000 } label='amenity site value' />
                </Grid>
                <Grid item flexGrow={ 1 } />
              </Grid>
            </Box> :
            <Box>
              <ValueDeliveredCard value={ messageCount * adImpressionCPM / 1000 } label='Messaging' days={ days } />
              <Divider/>
              <ValueDeliveredCard value={ amenityCount * adImpressionCPM / 1000 } label='Amenity Site' days={ days } />
            </Box>
        }
      </CardContent>
    </Card>
  );
}

export const DashboardLaborValueDelivered: FC<ChartProps> = ( { date } ) => {
  const defaultDurationDays = 30;
  const { preferences, isLoading: isLoadingPreferences } = useUserPreference();
  const [ showDetails, setShowDetails ] = useState( false );

  const days = useMemo( () => {
    if( isLoadingPreferences || !preferences ) return defaultDurationDays;
    return preferences.dashboardActivityDays as number || defaultDurationDays;
  }, [ preferences, isLoadingPreferences ] );

  const summary = useSummary<PatientActivityDatum[]>( 'patientActivity', { date, days } );
  const { appointmentCount, messageCount, profileCount, isEstimatedProfileCount } = useMemo( () => {
    const data = [ 'Appointments', 'Messages', 'Profiles' ].map( id => ( {
      id, data: summary.data?.map( d => {
        const { ymd, md, date } = d;
        const y = d[ id ] >= 1 ? d[ id ] : null; // no zeros for log scale
        return { x: ymd, y, ymd, md, date };
      } )
        .filter( d => !!d.y )
        .sort( ( a, b ) => a.ymd < b.ymd ? -1 : 1 ) || []
    } ) );
    const counts = Object.fromEntries( ( data || [] ).map( ( { id, data = [] } ) => [ id, data.reduce( ( tot, d ) => ( d.y || 0 ) + tot, 0 ) ] ) );
    const appointmentCount = counts.Appointments || 0;
    return {
      appointmentCount,
      messageCount: counts.Messages || 0,
      profileCount: counts.Profiles || ( appointmentCount * appointmentsToProfilesFactor ),
      isEstimatedProfileCount: !counts.Profiles,
    };
  }, [ summary, appointmentsToProfilesFactor ] );

  const hoursPerFTE = useMemo( () => {
    // Check for whether days should be scaled for weeks or months when
    // determining how many working hours are covered by that many days.
    const daysPerMonth = ( days % 7 ) ? 30 : 28;
    return fteHoursPerMonth * ( days / daysPerMonth );
  }, [ days, fteHoursPerMonth ] );

  const replacedFTEs = useMemo( () => {
    const profileFTEs = profileCount * manualAmenityMinutes / 60 / hoursPerFTE;
    const messageFTEs = messageCount * manualMessageMinutes / 60 / hoursPerFTE;
    return profileFTEs + messageFTEs;
  }, [ hoursPerFTE, manualAmenityMinutes, manualMessageMinutes, messageCount, profileCount ] );

  const colorPalettes = useColorPalettes();
  const [ green ] = useMemo( () => colorPalettes.spectrumGreenRed, [ colorPalettes ] );

  return (
    <Card
      elevation={ elevation }
      sx={ {
        minHeight: '25em',
        height: '100%',
        overflowX: 'visible',
        overflow: 'initial',
      } }
    >
      <CardHeader
        title={
          <Box sx={ { display: 'flex' } } >
            <Typography>Labor Saving Value Delivered</Typography>
            <DashboardInfo tag='labor-saving-deliver'>
              <i>Labor Saving Delivered</i> shows the amount of labor saved by the automation in this service.
              This chart examines the amount of time that would have been spent sending messages, configuring patient profiles, and
              how many full-time employees (FTEs) time have been replaced by this automation.
              <br/>Use the <CalculateIcon fontSize='small' sx={{ verticalAlign: 'bottom' }}/> Calculations button to view the data and math that make up these values.
            </DashboardInfo>
            <Box sx={ { flexGrow: 1 } } />
            <IconButton
              sx={ {
                // paddingTop: 0, paddingBottom: 0,
                marginTop: '-8px',
                marginBottom: '-8px',
              } }
              onClick={ () => setShowDetails( !showDetails ) }
            >
              { showDetails ? <LineChartIcon /> : <CalculateIcon /> }
            </IconButton>
          </Box>
        }
        titleTypographyProps={ {
          sx: {
            fontSize: '1.2rem',
            fontWeight: 400,
            // lineHeight: 1.334,
          }
        } }
        subheader={ `${ days } Days Ending ${ ( date ?? dayjs() ).format( 'MMM D, YYYY' ) }` }
        subheaderTypographyProps={ {
          sx: {
            fontSize: '0.9rem',
          }
        } }
      />
      <CardContent
        sx={ {
          overflowX: 'visible',
        } }
      >
        {
          showDetails ?
            <Box>
              <Grid container justifyContent='space-around' alignItems='end' marginY={ 3 }>
                <Grid direction='column' justifyItems='end'>
                  <LineItem value={ messageCount } label='messages' />
                  <LineItem operation='multiply' value={ manualMessageMinutes } label='minutes saved' />
                  <LineItem operation='divide' value={ 60 } label='min/hr' />
                  <LineItem operation='divide' value={ hoursPerFTE } label='hrs/FTE' />
                  <Divider sx={{ width: '100%' }}/>
                  <LineItem value={ messageCount * manualMessageMinutes / 60 / hoursPerFTE } label='FTEs time saved' />
                </Grid>
                <Grid direction='column' justifyItems='end'>
                  <LineItem value={ messageCount * manualMessageMinutes / 60 / hoursPerFTE } label='FTEs'/>
                  <LineItem operation='multiply' value={ hoursPerFTE } label='hrs/FTE' />
                  <LineItem operation='multiply' currency value={ frontDeskHourlyWage } label='per hour' />
                  <Divider sx={{ width: '100%' }}/>
                  <LineItem currency value={ messageCount * manualMessageMinutes / 60 * frontDeskHourlyWage } label='labor saved' />
                </Grid>
              </Grid>
              <Grid container justifyContent='space-around' alignItems='end' marginY={ 6 }>
                <Grid direction='column' justifyItems='end'>
                  { isEstimatedProfileCount ?
                    <>
                      <LineItem value={ appointmentCount } label='appointments' />
                      <LineItem operation='multiply' value={ appointmentsToProfilesFactor } label='avg profiles per appt'/>
                    </> :
                    <LineItem value={ profileCount } label='patient profiles' />
                  }
                  <LineItem operation='multiply' value={ manualAmenityMinutes } label='minutes saved' />
                  <LineItem operation='divide' value={ 60 } label='min/hr' />
                  <LineItem operation='divide' value={ hoursPerFTE } label='hrs/FTE' />
                  <Divider sx={{ width: '100%' }}/>
                  <LineItem value={ profileCount * manualAmenityMinutes / 60 / hoursPerFTE } label='FTEs time saved' />
                </Grid>
                <Grid direction='column' justifyItems='end'>
                  <LineItem value={ profileCount * manualAmenityMinutes / 60 / hoursPerFTE } label='FTEs' />
                  <LineItem operation='multiply' value={ hoursPerFTE } label='hrs/FTE' />
                  <LineItem operation='multiply' currency value={ frontDeskHourlyWage } label='per hour' />
                  <Divider sx={{ width: '100%' }}/>
                  <LineItem currency value={ profileCount * manualAmenityMinutes / 60 * frontDeskHourlyWage } label='labor cost saved' />
                </Grid>
              </Grid>
            </Box> :
            <Box>
              { /*  # of messages * 3 min per message / 60 min/hr * $20 wage   */ }
              <ValueDeliveredCard
                value={ messageCount * manualMessageMinutes / 60 * frontDeskHourlyWage }
                label='Messaging Labor Saved'
                days={ days }
                largeFontSize={ 54 }
                smallFontSize={ 16 }
              />
              <Divider/>
              { /*  # of profiles * 10 min per profile / 60 min/hr * $20 wage   */ }
              <ValueDeliveredCard
                value={ profileCount * manualAmenityMinutes / 60 * frontDeskHourlyWage }
                label='Profile Setup Labor'
                days={ days }
                largeFontSize={ 54 }
                smallFontSize={ 16 }
              />
              <Divider/>
              <Box
                marginBottom={ 2 }
                marginLeft={ 1 }

              >
                <Typography
                  fontSize={ 54 }
                  fontWeight='bold'
                  color={ replacedFTEs > 0 ? green : undefined }
                  sx={ { textAlign: 'right' } }
                >
                  { formatNumber( replacedFTEs ) }
                </Typography>
                <Typography
                  fontSize={ 18 }
                  fontWeight='medium'
                >
                  FTEs replaced
                </Typography>
              </Box>
            </Box>
        }
      </CardContent>
    </Card>
  );
}

export const DashboardReputationValueDelivered: FC<ChartProps> = ( { date } ) => {
  const defaultDurationDays = 30;
  const { preferences, isLoading: isLoadingPreferences } = useUserPreference();
  const [ showDetails, setShowDetails ] = useState( false );

  const days = useMemo( () => {
    if( isLoadingPreferences || !preferences ) return defaultDurationDays;
    return preferences.dashboardActivityDays as number || defaultDurationDays;
  }, [ preferences, isLoadingPreferences ] );

  const summary = useSummary<ReviewTrendDatum[]>( 'reviewActivity', { date, days } );
  const fiveStarReviewCount = useMemo( () => {
    return ( summary.data ?? [] ).reduce( ( sum, d ) => sum + ( d['5 stars'] || 0 ), 0 );
  }, [ summary ] );

  return (
    <Card
      elevation={ elevation }
      sx={ {
        minHeight: '25em',
        height: '100%',
        overflowX: 'visible',
        overflow: 'initial',
      } }
    >
      <CardHeader
        title={
          <Box sx={ { display: 'flex' } } >
            <Typography>Reputation Value Delivered</Typography>
            <DashboardInfo tag='reputation-value-del'>
              <i>Reputation Value Delivered</i> shows the value of the 5-star reviews received over a period of time.
              <br/>Use the <CalculateIcon fontSize='small' sx={{ verticalAlign: 'bottom' }}/> Calculations button to view the data and math that make up these values.
            </DashboardInfo>
            <Box sx={ { flexGrow: 1 } } />
            <IconButton
              sx={ {
                // paddingTop: 0, paddingBottom: 0,
                marginTop: '-8px',
                marginBottom: '-8px',
              } }
              onClick={ () => setShowDetails( !showDetails ) }
            >
              { showDetails ? <LineChartIcon /> : <CalculateIcon /> }
            </IconButton>
          </Box>
        }
        titleTypographyProps={ {
          sx: {
            fontSize: '1.2rem',
            fontWeight: 400,
            // lineHeight: 1.334,
          }
        } }
        subheader={ `${ days } Days Ending ${ ( date ?? dayjs() ).format( 'MMM D, YYYY' ) }` }
        subheaderTypographyProps={ {
          sx: {
            fontSize: '0.9rem',
          }
        } }
      />
      <CardContent
        sx={ {
          overflowX: 'visible',
        } }
      >
        {
          showDetails ?
            <Box>
              <Grid container justifyContent='space-around' alignItems='end'>
                <Grid direction='column' justifyItems='end' margin={ 3 }>
                  <LineItem value={ fiveStarReviewCount } label='five-star reviews' />
                  <LineItem operation='multiply' currency value={ fiveStarReviewValue } label='per review' />
                  <Divider sx={{ width: '100%' }}/>
                  <LineItem currency value={ fiveStarReviewCount * fiveStarReviewValue } label='value in new reviews' />
                </Grid>
                <Grid item flexGrow={ 1 }/>
              </Grid>
            </Box> :
            <ValueDeliveredCard value={ fiveStarReviewCount * fiveStarReviewValue } label='Reviews' days={ days } />
        }
      </CardContent>
    </Card>
  );
}

export const DashboardTotalValueDelivered: FC<ChartProps> = ( { date } ) => {
  const defaultDurationDays = 30;
  const { preferences, isLoading: isLoadingPreferences } = useUserPreference();
  const [ showDetails, setShowDetails ] = useState( false );

  const days = useMemo( () => {
    if( isLoadingPreferences || !preferences ) return defaultDurationDays;
    return preferences.dashboardActivityDays as number || defaultDurationDays;
  }, [ preferences, isLoadingPreferences ] );

  const colorPalettes = useColorPalettes();
  const [ green ] = useMemo( () => colorPalettes.spectrumGreenRed, [ colorPalettes ] );

  const patientActivitySummary = useSummary<PatientActivityDatum[]>( 'patientActivity', { date, days } );
  const { messageCount, amenityCount, profileCount } = useMemo( () => {
    const data = [ 'Appointments', 'Messages', 'Amenities', 'Profiles' ].map( id => ( {
      id, data: patientActivitySummary.data?.map( d => {
        const { ymd, md, date } = d;
        const y = d[ id ] >= 1 ? d[ id ] : null; // no zeros for log scale
        return { x: ymd, y, ymd, md, date };
      } )
        .filter( d => !!d.y )
        .sort( ( a, b ) => a.ymd < b.ymd ? -1 : 1 ) || []
    } ) );
    const counts = Object.fromEntries( ( data || [] ).map( ( { id, data = [] } ) => [ id, data.reduce( ( tot, d ) => ( d.y || 0 ) + tot, 0 ) ] ) );
    const appointmentCount = counts.Appointments || 0;
    return {
      amenityCount: counts.Amenities || appointmentCount * appointmentsToAmenitiesFactor,
      messageCount: counts.Messages || 0,
      profileCount: counts.Profiles || appointmentCount * appointmentsToProfilesFactor,
    };
  }, [ patientActivitySummary ] );

  const messagingValue = useMemo( () => messageCount * adImpressionCPM / 1000, [ adImpressionCPM, messageCount ] );
  const amenityValue = useMemo( () => amenityCount * adImpressionCPM / 1000, [ adImpressionCPM, amenityCount ] );

  const messagingLaborSaved = useMemo( () => messageCount * manualMessageMinutes / 60 * frontDeskHourlyWage, [ frontDeskHourlyWage, manualMessageMinutes, messageCount ] );
  const profileSetupLaborSaved = useMemo( () => profileCount * manualAmenityMinutes / 60 * frontDeskHourlyWage, [ frontDeskHourlyWage, manualMessageMinutes, profileCount ] );

  const reviewActivitySummary = useSummary<ReviewTrendDatum[]>( 'reviewActivity', { date, days } );
  const fiveStarReviewCount = useMemo( () => {
    return ( reviewActivitySummary.data ?? [] ).reduce( ( sum, d ) => sum + ( d['5 stars'] || 0 ), 0 );
  }, [ reviewActivitySummary ] );

  const reputationValue = useMemo( () => fiveStarReviewCount * fiveStarReviewValue, [ fiveStarReviewCount, fiveStarReviewValue ] );

  const recoverableRevenueSummary = useSummary<RecoverableRevenueTrendDatum[]>( 'recoverableRevenueTrend', { date, months: 1 } );
  const recoverableRevenueValue = useMemo( () => {
    if( !recoverableRevenueSummary.data ) return 0;
    return recoverableRevenueSummary.data.reverse()[ 0 ].total || 0;
  }, [ recoverableRevenueSummary.data ] )

  const totalValue = useMemo( () => {
    return messagingValue + amenityValue + messagingLaborSaved + profileSetupLaborSaved + reputationValue + recoverableRevenueValue;
  }, [ amenityValue, messagingLaborSaved, messagingValue, profileSetupLaborSaved, recoverableRevenueValue, reputationValue ] );

  const parentOrg = useParentOrg();
  const messagingCost = useMemo( () => {
    const monthlyEmailCost = 6;
    if( !parentOrg.name?.match( /Summit/ ) ) return monthlyEmailCost;
    const ym = ( date ?? dayjs() ).format( 'yyyy-mm' );
    return ( SummitMessagingCosts[ ym ] || 0 ) + monthlyEmailCost;
  }, [ date, parentOrg.name, SummitMessagingCosts ] );

  const totalCost = useMemo( () => monthlyCost + messagingCost, [ messagingCost, monthlyCost ] );

  const hoursPerFTE = useMemo( () => {
    // Check for whether days should be scaled for weeks or months when
    // determining how many working hours are covered by that many days.
    const daysPerMonth = ( days % 7 ) ? 30 : 28;
    return fteHoursPerMonth * ( days / daysPerMonth );
  }, [ days, fteHoursPerMonth ] );

  const replacedFTEs = useMemo( () => {
    const profileFTEs = profileCount * manualAmenityMinutes / 60 / hoursPerFTE;
    const messageFTEs = messageCount * manualMessageMinutes / 60 / hoursPerFTE;
    return profileFTEs + messageFTEs;
  }, [ hoursPerFTE, manualAmenityMinutes, manualMessageMinutes, messageCount, profileCount ] );


  return (
    <Card
      elevation={ elevation }
      sx={ {
        minHeight: '25em',
        height: '100%',
        overflowX: 'visible',
        overflow: 'initial',
      } }
    >
      <CardHeader
        title={
          <Box sx={ { display: 'flex' } } >
            <Typography>Bottom Line Results Delivered by AIC</Typography>
            <DashboardInfo tag='%C2%A0'>
              <i>Bottom Line</i> shows the return on investment for all metrics over a period of time.
              <br/>Use the <CalculateIcon fontSize='small' sx={{ verticalAlign: 'bottom' }}/> Calculations button to view the data and math that make up these values.
            </DashboardInfo>
            <Box sx={ { flexGrow: 1 } } />
            <IconButton
              sx={ {
                // paddingTop: 0, paddingBottom: 0,
                marginTop: '-8px',
                marginBottom: '-8px',
              } }
              onClick={ () => setShowDetails( !showDetails ) }
            >
              { showDetails ? <LineChartIcon /> : <CalculateIcon /> }
            </IconButton>
          </Box>
        }
        titleTypographyProps={ {
          sx: {
            fontSize: '1.2rem',
            fontWeight: 400,
            // lineHeight: 1.334,
          }
        } }
        subheader={ `${ days } Days Ending ${ ( date ?? dayjs() ).format( 'MMM D, YYYY' ) }` }
        subheaderTypographyProps={ {
          sx: {
            fontSize: '0.9rem',
          }
        } }
      />
      <CardContent
        sx={ {
          overflowX: 'visible',
        } }
      >
        {
          showDetails ?
            <Box sx={{ fontSize: '16px' }}>
              <Grid container justifyContent='space-around' alignItems='end' marginBottom={ 3 }>
                <Grid direction='column' justifyItems='end' marginX={ 3 }>
                  <LineItem currency value={ messagingValue } label='Messaging Value' />
                  <LineItem operation='add' currency value={ amenityValue } label='Amenity Site Value' />
                  <LineItem operation='add' currency value={ messagingLaborSaved } label='Messaging Labor Value' />
                  <LineItem operation='add' currency value={ profileSetupLaborSaved } label='Profile Setup Labor Value' />
                  <LineItem operation='add' currency value={ reputationValue } label='Reputation Value' />
                  <LineItem operation='add' currency value={ recoverableRevenueValue } label='Recoverable Revenue Value' />
                  <Divider sx={{ width: '100%' }}/>
                  <LineItem currency value={ totalValue } label='Total Value Delivered' />
                </Grid>
                <Grid item flexGrow={ 1 }/>
              </Grid>
              <Grid container justifyContent='space-around' alignItems='end'>
                <Grid direction='column' justifyItems='end'>
                  <LineItem currency value={ totalValue } label='Value provided' />
                  <LineItem operation='divide' currency value={ totalCost } label='Total cost' />
                  <Divider sx={{ width: '100%' }}/>
                  <LineItem value={ totalValue / totalCost } label='x Return on Investment' />
                </Grid>
                <Grid direction='column' justifyItems='end'>
                  <LineItem currency value={ totalValue } label='Value provided' />
                  <LineItem operation='subtract' currency value={ totalCost } label='Total cost' />
                  <LineItem operation='divide' currency value={ totalCost } label='Total cost' />
                  <LineItem operation='multiply' value={ 100 } label='%' />
                  <Divider sx={{ width: '100%' }} />
                  <LineItem value={ ( totalValue - totalCost ) / totalCost * 100 } label='% Return on Investment' />
                </Grid>
              </Grid>
            </Box> :
            <Box>
              <Box marginBottom={ 2 } marginLeft={ 1 } >
                <Typography
                  fontSize={ 60 }
                  fontWeight='bold'
                  color={ totalValue > 0 ? green : undefined }
                  sx={ { textAlign: 'right' } }
                >
                  { formatCurrency( Math.round( totalValue ) ) }
                </Typography>
                <Typography
                  fontSize={ 18 }
                  sx={ { textAlign: 'right' }}
                >
                  Annualized: { formatCurrency( Math.round( totalValue * 365 / days ) ) }
                </Typography>
                <Typography
                  fontSize={ 18 }
                  fontWeight='medium'
                >
                  Total Financial Value Delivered
                </Typography>
              </Box>
              <Divider />
              <Box
                marginBottom={ 2 }
                marginLeft={ 1 }
              >
                <Grid container justifyContent='space-between'>
                  <Typography
                    fontSize={ 60 }
                    fontWeight='bold'
                    color={ totalValue > 0 ? green : undefined }
                    sx={ { textAlign: 'right' } }
                  >
                    { formatNumber( totalValue / totalCost ) }x
                  </Typography>
                  <Typography
                    fontSize={ 60 }
                    fontWeight='bold'
                    color={ totalValue > 0 ? green : undefined }
                    sx={ { textAlign: 'right' } }
                  >
                    { formatNumber( ( totalValue - totalCost ) / totalCost * 100, { maximumSignificantDigits: 4 } ) }%
                  </Typography>
                </Grid>
                <Typography
                  fontSize={ 20 }
                  sx={ { textAlign: 'right' }}
                >{ ' ' }</Typography>
                <Typography
                  fontSize={ 18 }
                  fontWeight='medium'
                >
                  Return on Investment Delivered
                </Typography>
              </Box>
              <Divider />
              <Box
                marginBottom={ 2 }
                marginLeft={ 1 }
              >
                <Typography
                  fontSize={ 60 }
                  fontWeight='bold'
                  color={ replacedFTEs > 0 ? green : undefined }
                  sx={ { textAlign: 'right' } }
                >
                  { formatNumber( replacedFTEs ) }
                </Typography>
                <Typography
                  fontSize={ 20 }
                  sx={ { textAlign: 'right' }}
                >{ ' ' }</Typography>
                <Typography
                  fontSize={ 18 }
                  fontWeight='medium'
                >
                  Full-Time Employees' Work Delivered by Automation
                </Typography>
              </Box>
            </Box>
        }
      </CardContent>
    </Card>
  );
}


export const DashboardRevenueLoss: FC<ChartProps> = ( { date } ) => {
  const { preferences, isLoading: isLoadingPreferences } = useUserPreference();
  const defaultDurationMonths = 6;

  const durationMonths = useMemo( () => {
    if( isLoadingPreferences || !preferences?.dashboardTrendMonths ) return defaultDurationMonths;
    return preferences.dashboardTrendMonths as number;
  }, [ preferences, isLoadingPreferences ] );

  const summary = useSummary<PatientActivityDatum[]>( 'recoverableRevenueTrend', { date, months: durationMonths } );

  const { billingErrorLoss, unfinishedLoss, totalLoss } = useMemo( () => {
    const data = [ 'Appointments Billing Audit', 'Unfinished Appointments', 'total' ].map( id => ( {
      id, data: summary.data?.map( d => {
        const { ymd, md, date } = d;
        const y = d[ id ] >= 1 ? d[ id ] : null; // no zeros for log scale
        return { x: ymd, y, ymd, md, date };
      } )
        .filter( d => !!d.y )
        .sort( ( a, b ) => a.ymd < b.ymd ? -1 : 1 ) || []
    } ) );
    const counts = Object.fromEntries( ( data || [] ).map( ( { id, data = [] } ) => [ id, data.reduce( ( tot, d ) => ( d.y || 0 ) + tot, 0 ) ] ) );
    return {
      billingErrorLoss: counts[ 'Appointments Billing Audit' ] || 0,
      unfinishedLoss: counts[ 'Unfinished Appointments' ] || 0,
      totalLoss: counts.total || 0,
    };
  }, [ summary ] );

  return (
    ( <Card
      elevation={ elevation }
      sx={ {
        minHeight: '25em',
        height: '100%',
        overflowX: 'visible',
        overflow: 'initial',
      } }
    >
      <CardHeader
        title={
          <Box sx={ { display: 'flex' } } >
            <Typography>Recoverable Revenue Loss</Typography>
            <DashboardInfo tag='%C2%A0-3'>
              <i>Recoverable Revenue Loss</i> examines a period of time and identifies how much additional revenue could
              have been received by the organization if billing and appointment anomalies were rectified.
            </DashboardInfo>
            <Box sx={ { flexGrow: 1 } } />
          </Box>
        }
        titleTypographyProps={ {
          sx: {
            fontSize: '1.2rem',
            fontWeight: 400,
            // lineHeight: 1.334,
          }
        } }
        subheader={ `${ durationMonths } Months Ending ${ ( date ?? dayjs() ).format( 'MMM YYYY' ) }` }
        subheaderTypographyProps={ {
          sx: {
            fontSize: '0.9rem',
          }
        } }
      />
      <CardContent
        sx={ {
          overflowX: 'visible',
        } }
      >
        <ValueDeliveredCard
          loss
          value={ billingErrorLoss }
          label='Appointments Billing Audit'
          largeFontSize={ 60 }
        />
        <Divider />
        <ValueDeliveredCard
          loss
          value={ unfinishedLoss }
          label='Unfinished Appointments'
          largeFontSize={ 60 }
        />
        <Divider />
        <ValueDeliveredCard
          loss
          value={ totalLoss }
          label='Total' days={ durationMonths * 30 }
          largeFontSize={ 60 }
          smallFontSize={ 20 }
        />
      </CardContent>
    </Card > )
  )
}
