import { SsidChart as LineChartIcon, TableChartOutlined as TableIcon } from '@mui/icons-material';
import { Box, Card, CardContent, CardHeader, IconButton, Typography, useTheme } from '@mui/material';
import { GridColType } from '@mui/x-data-grid-premium';
import { Pie } from '@nivo/pie';
import { FC, useMemo, useState } from 'react';
import { Loading, useGetList, useRedirect } from 'react-admin';
import { AnomalyReportDocument } from './AnomalyReports';
import { makePieChartTooltip } from './DashboardTooltip';
import { ChartProps, elevation, PieDatum } from './DashboardWidget';
import useColorPalettes from './use-color-palettes';
import usePatterns from './use-patterns';
import useSummary from './use-summary';
import { DashboardTable } from './DashboardDatagrid';
import { arcLabelWithOutline } from './DashboardReputationSummary';
import { DashboardInfo } from './DashboardInfo';


const anomalyIssueMap: Record<string, string> = {
  'Inactive location has organization set': 'Org setup',
  'Organization missing phone': 'Org setup',
  'Organization missing email': 'Org setup',
  'Organization missing weburl': 'Org setup',
  'Organization missing amenity options': 'Org setup',
  'Organization missing branding assets': 'Org setup',

  'Location missing phone': 'Loc setup',
  'Location missing email': 'Loc setup',
  'Location missing weburl': 'Loc setup',
  'Location missing hours': 'Loc setup',
  'Location missing signature': 'Loc setup',
  'Location missing SEO tag': 'Loc setup',
  'Location missing nickname': 'Loc setup',
  'Location missing review site': 'Loc setup',
  'Location missing amenity assets': 'Loc setup',
  'Location missing transportation options': 'Loc setup',

  'Practitioner missing sms': 'Prac setup',
  'Practitioner missing phone': 'Prac setup',
  'Practitioner missing email': 'Prac setup',

  'Appointment Not Resolved - Stuck in Arrived Status - No Encounter': 'Appt stuck',
  'Appointment Not Resolved - Stuck in Booked Status - No Encounter': 'Appt stuck',
  'Appointment Not Resolved - Stuck in Proposed Status - No Encounter': 'Appt stuck',

  'INCOME AUDIT: Pending Appointments with Encounter': 'Appt stuck',
  'INCOME AUDIT: Arrived Appointments with Encounter': 'Appt stuck',
  'Check-In Appointment with Encounter': 'Appt stuck',
  'Appointment Not Resolved - Stuck in Checked-In Status - No Encounter': 'Appt stuck',
  'Appointment Not Resolved - Stuck in Pending Status - No Encounter': 'Appt stuck',

  'INCOME AUDIT: Completed/Fulfilled Appointments without Encounter - Unbilled Visit': 'Lost income',
  'INCOME AUDIT: No-Show Appointments with Encounter': 'Lost income',
  'INCOME AUDIT: Cancelled Appointments with Encounter': 'Lost income',

  'INCOME AUDIT: Booked Appointments with Encounter': 'Lost income',
  'INCOME AUDIT: Entered-In-Error Appointments with Encounter': 'Lost income',
  'INCOME AUDIT: Proposed Appointments with Encounter': 'Lost income',
  'INCOME AUDIT: Wait Listed Appointment with Encounter': 'Lost income',
};
export const mapAnomalyIssue = ( key: string ): string => anomalyIssueMap[ key ] || key;

export const DashboardAnomaliesFound: FC<ChartProps> = ( { date } ) => {
  const theme = useTheme();
  const redirect = useRedirect();
  const isSmall = false;
  const cacheKey = 'dashboard-anomalies-found';
  const { data: weekly } = useGetList<AnomalyReportDocument>( 'anomalyreports', { filter: { reportIntervalType: 'Weekly' }, sort: { field: 'appointmentStopTime', order: 'DESC' }, pagination: { perPage: 2, page: 1 } } );
  const apptStuckId = useMemo( () => weekly?.find( r => r.reportType == 'Unfinished Appointments' )?.id, [ weekly ] );
  const lostIncomeId = useMemo( () => weekly?.find( r => r.reportType == 'Appointments Billing Audit' )?.id, [ weekly ] );
  const [ showDatagrid, setShowDatagrid ] = useState( false );

  const summary = useSummary<{ type: string, category: string, count: number }[]>( 'anomaliesFound', { date } );

  const data = useMemo<PieDatum[]>( () => {
    if( !summary.data ) return [];
    const data = summary.data.reduce( ( data, { type, count } ) => {
      const key = mapAnomalyIssue( type );
      data[ key ] = count + ( data[ key ] || 0 );
      return data;
    }, {} as Record<string, number> );
    let rows = Object.entries( data ).map( ( [ id, value ] ) => ( { id, value } ) );
    if( rows.length == 0 ) {
      // @ts-ignore:2322
      rows = [ { id: null, label: 'No data', value: true } ];
    }
    return rows;
  }, [ summary.data ] );

  const total = useMemo( () => data.reduce( ( tot, d ) => tot += d.value, 0 ) ?? 0, [ data ] );

  const isEmpty = useMemo( () => data[ 0 ]?.id === null, [ data ] );

  // const colors = categoricalColorSchemes.pastel2.slice( 0, data.length );
  const colorPalettes = useColorPalettes();
  const colors = useMemo( () => colorPalettes.discretePrimary, [ colorPalettes ] );
  const patterns = usePatterns();

  return (

    <Card
      elevation={ elevation }
      sx={ {
        minHeight: '25em',
        overflowX: 'visible',
        overflow: 'initial',
      } }
    >
      <CardHeader
        title={
          <Box sx={ { display: 'flex' } } >
            <Typography>Anomolies Found</Typography>
            <DashboardInfo tag='anomalies-found'>
              <i>Anomalies Found</i> highlights all accounting issues and EMR configuration issues found in the EMR within the last week.
              Clicking on the chart will redirect you to the weekly anomaly report.
            </DashboardInfo>
            <Box sx={ { flexGrow: 1 } } />
            <IconButton
              sx={ {
                // paddingTop: 0, paddingBottom: 0,
                marginTop: '-8px',
                marginBottom: '-8px',
              } }
              onClick={ () => setShowDatagrid( !showDatagrid ) }
            >
              { showDatagrid ? <LineChartIcon /> : <TableIcon /> }
            </IconButton>
          </Box>
        }
        titleTypographyProps={ {
          sx: {
            fontSize: '1.2rem',
            fontWeight: 400,
            // lineHeight: 1.334,
          }
        } }
        subheader={ date ? `Week Ending ${ date.format( 'MMM D' ) }` : 'Previous Week' }  // This is WEEK, not 7 DAYS
        subheaderTypographyProps={ {
          sx: {
            fontSize: '0.9rem',
          }
        } }
      // avatar={ <DashboardAlertIcon /> }
      />
      <CardContent
        sx={ {
          overflowX: 'visible',
        } }
      >
        { summary.isLoading
          ? <Box>
            <Loading
              loadingPrimary=''
              loadingSecondary=''
              sx={ {
                '@media (min-width: 0)': {
                  marginTop: 0,
                  marginBottom: 7,
                  height: '100%',
                  width: 500,
                  minHeight: 400,
                }
              } }
            />
          </Box>

          :
          <Box
            sx={ {
              position: 'relative'
            } }
          >
            { showDatagrid
              ? (
                <DashboardTable
                  columns={ [
                    { field: 'id', headerName: 'Type', flex: 1 },
                    { field: 'value', headerName: 'Count', flex: 1, type: 'number' as GridColType },
                  ] }
                  rows={ isEmpty ? [] : data }
                  exportFileName={ cacheKey }
                />
              )
              : <>
                <Pie
                  data={ data }
                  margin={ { top: 0, right: 160, bottom: 10, left: 10 } }
                  sortByValue
                  arcLabelsSkipAngle={ 20 }
                  isInteractive={ !isEmpty }
                  enableArcLabels={ !isEmpty }
                  enableArcLinkLabels={ false }
                  arcLabel={ ( d ) => `${ ( 100 * d.value / total ).toFixed( 0 ) }% (${ d.value })` }
                  arcLabelsRadiusOffset={ 0.6 }
                  arcLabelsComponent={ arcLabelWithOutline }

                  onClick={ ( datum ) => {
                    const { id } = datum;
                    switch( id ) {
                      case 'Org setup':
                        return redirect( '/anomalies/dashboardalerts?filter=%7B"resourceType"%3A"Organization"%2C"status"%3A"active"%7D' );
                      case 'Loc setup':
                        return redirect( '/anomalies/dashboardalerts?filter=%7B"resourceType"%3A"Location"%2C"status"%3A"active"%7D' );
                      case 'Prac setup':
                        return redirect( '/anomalies/dashboardalerts?filter=%7B"resourceType"%3A"Practitioner"%2C"status"%3A"active"%7D' );
                      case 'Lost income':
                        return redirect( 'show', '/anomalyreports', lostIncomeId );
                      case 'Appt stuck':
                        return redirect( 'show', '/anomalyreports', apptStuckId );
                    }
                  } }

                  theme={ {
                    background: theme.palette?.background?.default,
                    axis: {
                      legend: { text: { fontSize: isSmall ? 16 : 18 } },
                      ticks: { text: { fontSize: isSmall ? 0 : 14 } },
                    },
                    labels: { text: { fill: '#333', fontSize: 14, fontWeight: 'bold' } },
                    legends: { text: { fontSize: 14 } },
                    text: { fill: theme.palette?.mode === 'dark' ? '#ccc' : '#333' },
                    tooltip: {
                      container: {
                        backgroundColor: theme.palette?.background?.default,
                        color: theme.palette?.mode === 'dark' ? '#ccc' : '#333',
                      },
                    },
                  } }
                  tooltip={ makePieChartTooltip( theme, total ) }

                  colors={ isEmpty ? [ theme.palette?.mode === 'dark' ? '#555' : '#ccc' ] : colors }
                  defs={ patterns.defs }
                  fill={ patterns.fillByKeys( data.map( d => d.id ) ) }

                  width={ 500 }
                  height={ 400 }
                  legends={ [
                    {
                      anchor: 'right',
                      direction: 'column',
                      justify: false,
                      translateX: 110,
                      translateY: -80,
                      itemsSpacing: 4,
                      itemWidth: 80,
                      itemHeight: 20,
                      itemDirection: 'left-to-right',
                      itemOpacity: 0.85,
                      symbolSize: 20,
                      symbolShape: 'circle',
                      effects: [
                        {
                          on: 'hover',
                          style: {
                            itemOpacity: 1
                          }
                        }
                      ]
                    }
                  ] }
                />
              </>
            }
          </Box>
        }
      </CardContent>
    </Card>

  );

}
