import { Card, CardContent, CardHeader } from '@mui/material';
import { FC, useMemo } from 'react';
import { DashboardDatagrid } from './DashboardDatagrid';
import { ChartProps, elevation } from './DashboardWidget';
import { useUserPreference } from './UserPreferences';
import useSummary from './use-summary';
import { PatientActivityDatum } from './DashboardPatientActivity';

export const SummitMessagingCosts: Record<string,number> = {
  '2022-09': 1.17,
  '2022-10': 0.32,
  '2022-11': 0.6,
  '2022-12': 1.12,
  '2023-01': 0.23,
  '2023-02': 1.51,
  '2023-03': 1.47,
  '2023-04': 1.57,
  '2023-05': 53.96,
  '2023-06': 366.34,
  '2023-07': 484.99,
  '2023-08': 427.54,
  '2023-09': 418.27,
  '2023-10': 376.33,
  '2023-11': 352.88,
  '2023-12': 202.96,
  '2024-01': 342.32,
  '2024-02': 290.4,
  '2024-03': 277.2,
  '2024-04': 126.82,
  '2024-05': 50.93,
  '2024-06': 51.87,
  '2024-07': 55,
  '2024-08': 45.93,
  '2024-09': 49.32,
  '2024-10': 38.85
};

export const DashboardMessagingCost: FC<ChartProps> = ( { date } ) => {
  const { preferences, isLoading: isLoadingPreferences } = useUserPreference();
  const defaultDurationMonths = 6;
  const cacheKey = 'dashboard-messaging-cost';

  const durationMonths = useMemo( () => {
    if( isLoadingPreferences || !preferences?.dashboardTrendMonths ) return defaultDurationMonths;
    return preferences.dashboardTrendMonths as number;
  }, [ preferences, isLoadingPreferences ] );

  const summary = useSummary<PatientActivityDatum[]>( 'patientTrend', { date, months: durationMonths } );

  const data = useMemo( () => summary.data?.map( ( d, id ) => {
    const monthlyEmailCost = 6;
    const ym = d.ymd.slice( 0, 7 );
    const messagingCost = SummitMessagingCosts[ ym ] || 0;
    return {
      ...d,
      id,
      ymd: ym,
      'Total Cost': ( monthlyEmailCost + messagingCost ).toLocaleString( undefined, { style: 'currency', currency: 'USD' } ),
    };
  } ) || [] , [ date, summary.data ] );

  return (
    ( <Card
      elevation={ elevation }
      sx={ {
        minHeight: '25em',
        height: '100%',
        overflowX: 'visible',
        overflow: 'initial',
      } }
    >
      <CardHeader
        title="Messaging Cost"
        titleTypographyProps={ {
          sx: {
            fontSize: '1.2rem',
            fontWeight: 400,
            // lineHeight: 1.334,
          }
        } }
        subheader={ date ? `${ durationMonths } Months Ending ${ date.format( 'MMM YYYY' ) }` : `Previous ${ durationMonths } Months` }
        subheaderTypographyProps={ {
          sx: {
            fontSize: '0.9rem',
          }
        } }
      />
      <CardContent
        sx={ {
          overflowX: 'visible',
        } }
      >
        <DashboardDatagrid
          rows={ data }
          columns={ [
            'Messages',
            'Total Cost',
          ] }
          exportFileName={ cacheKey }
        />
      </CardContent>
    </Card > )
  )
}