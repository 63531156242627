import { SaveAlt as ExportIcon } from '@mui/icons-material';
import { Box, Card, CardContent, CardHeader, Typography } from '@mui/material';
import { GridRowParams } from '@mui/x-data-grid-premium';
import { BarDatum } from '@nivo/bar';
import { FC } from 'react';
import { useGetMany, useRedirect } from 'react-admin';
import { ChartProps, elevation } from './DashboardWidget';
import useSummary from './use-summary';
import { DashboardTable } from './DashboardDatagrid';
import { DashboardInfo } from './DashboardInfo';


interface UnreachableAppointmentDatum extends BarDatum {
  _id: string; // appointment Id
  patient: string; // patient id
  appointmentTypeDisplay: string; // "Cataract Work Up",
  date: string; //  "2024-08-15",
  locationFullName: string; //  "Linda Vision RMD",
  patientFullName: string; //  "Thuan Nguyen",
  practitionerFullName: string; //  "Bach Lan Linda Vu",
  startTime: string; //  "2024-08-15T15:45:00.000Z",
  status: string; //  "pending",
}

export const DashboardUnreachableAppointments: FC<ChartProps> = ( { date } ) => {
  const redirect = useRedirect();
  const cacheKey = 'dashboard-unreachable-appointments';

  const summary = useSummary<UnreachableAppointmentDatum[]>( 'appointmentsWithoutNotice', { date } );

  const { data: recipients } = useGetMany( 'recipients', { ids: ( summary.data || [] ).map( d => d.patient ) }, { enabled: ( summary.data || [] ).length > 0 } );

  const onClickRecipient = ( id: string ) => {
    const recipient = ( recipients || [] ).find( r => r.id == id );
    if( !recipient ) return;
    redirect( '/contacteditor', undefined, undefined, undefined, { recipient } )
  };

  return (
    ( <Card
      elevation={ elevation }
      sx={ {
        minHeight: '25em',
        height: '100%',
        overflowX: 'visible',
        overflow: 'initial',
      } }
    >
      <CardHeader
        title={
          <Box sx={ { display: 'flex' } } >
            <Typography>Upcoming Unreachable Appointments</Typography>
            <DashboardInfo tag='upcoming-unreachable' placement='bottom-end' >
              <i>Upcoming Unreachable Appointments</i> shows patients and their appointment dates who do not have any valid
              contact information or who have unsubscribed, who have appointments coming in the next 7 calendar days.
              Export the data using the <ExportIcon fontSize='small' sx={{ verticalAlign: 'bottom' }} /> Export button.
            </DashboardInfo>
            <Box sx={ { flexGrow: 1 } } />
          </Box>
        }
        titleTypographyProps={ {
          sx: {
            fontSize: '1.2rem',
            fontWeight: 400,
            // lineHeight: 1.334,
          }
        } }
        subheader={ date ? `7 Days Starting ${ date.format( 'MMM D' ) }` : 'Next 7 Days' }
        subheaderTypographyProps={ {
          sx: {
            fontSize: '0.9rem',
          }
        } }
      />
      <CardContent
        sx={ {
          overflowX: 'visible',
        } }
      >
        <DashboardTable
          onRowClick={ ( params: GridRowParams<UnreachableAppointmentDatum> ) => {
            onClickRecipient( params.row.patient )
          } }
          rows={ summary.isLoading || !summary.data
            ? []
            : summary.data.map( ( d, id ) => {
              const { patient, patientFullName, date, status, practitionerFullName, appointmentTypeDisplay } = d;
              return { id, patient, patientFullName, date, status, practitionerFullName, appointmentTypeDisplay };
            } ) }
          columns={ [
            { field: 'date', headerName: 'Appointment', flex: 2, sortable: true },
            { field: 'patientFullName', headerName: 'Patient', sortable: false, flex: 2 },
            { field: 'practitionerFullName', headerName: 'Practitioner', sortable: false, flex: 2 },
            { field: 'appointmentTypeDisplay', headerName: 'Type', flex: 2, sortable: true },
          ] }
          exportFileName={ cacheKey }
        />
      </CardContent>
    </Card > )
  );

}

