/*eslint @typescript-eslint/no-unused-vars: "warn"*/
import { debounce, range } from 'lodash';
import { FC, useCallback, useEffect } from 'react';
import { ArrayField, ArrayInput, BooleanInput, ChipField, Datagrid, Edit, EditButton, EditProps, List, ListProps, NumberField, NumberInput, ReferenceManyField, required, SaveButton, SelectField, SelectInput, Show, ShowProps, SimpleFormIterator, SimpleFormProps, SingleFieldList, TextField, TextInput, TimeInput, Toolbar, ToolbarProps } from 'react-admin';
import { useFormState } from 'react-hook-form';
import { BooleanColorField } from './BooleanColorField';
import { apiUrl, choices, httpClient } from './DataProvider';
import { SimpleFormWithLocation, SimpleShowLayoutWithLocation, useListAppLocation } from './MyBreadcrumb';
import { useShowScheduleFields, useUnitMinutes } from './Organizations';
import { formatBaseTimeInput, parseBaseTimeInput, RetailHoursField } from './RetailHours';
import { TimestampsField } from './TimestampsField';
import { Childless } from './types';
import { TimeStringPeriodField } from './Practitioners';
import { Typography, Box } from '@mui/material';


export { default as AppointmentTypeIcon } from '@mui/icons-material/SpeakerNotes';

export const choicesOverbook = [
  { id: 1, name: 'Single' },
  { id: 2, name: 'Double' },
  { id: 3, name: 'Triple' },
  { id: 4, name: 'Quadruple' },
]

export const AppointmentTypeList: FC<Childless<ListProps>> = props => {
  useListAppLocation( 'appointment' );
  const showScheduleFields = useShowScheduleFields();
  const unitMinutes = useUnitMinutes();

  const fetchNewEmr = useCallback( debounce( () => {
    httpClient( `${ apiUrl }/appointmenttypes/actions/fetchNewEmr`, { method: 'POST' } );
  }, 10000, { leading: true, trailing: true } ), [] )

  useEffect( () => {
    fetchNewEmr();
  }, [ fetchNewEmr ] );

  const choicesDuration = range( 0, 10 ).map( id => ( { id, name: `${ id * unitMinutes }` } ) );

  // const filterDefaultValues = {};

  return (
    <List { ...props }
      exporter={ false }
      sort={ { field: 'name', order: 'ASC' } }
      perPage={ 25 }
    >
      <Datagrid rowClick="show" bulkActionButtons={ false } >
        <TextField label="Code" source="id" />
        <TextField source="name" />
        { !showScheduleFields &&
          <TextField source="comment" />
        }
        { showScheduleFields &&
          <TextField source="label" label='Patient label' />
        }
        { showScheduleFields &&   // <NumberField source='units' />
          <SelectField source='units' label='Duration' choices={ choicesDuration } textAlign='right' />
        }
        { showScheduleFields &&
          <RetailHoursField source='dayAvailableOpenClose' label='Patient scheduling' />
        }
        { showScheduleFields &&
          <BooleanColorField source='newPatient' label='New patient' FalseIcon={ null } textAlign='center' />
        }


        { showScheduleFields &&
          <ReferenceManyField reference="practitioners" source='id' target="appointmentTypeCodesEnabledForSchedule" label="Practitioners">
            <SingleFieldList >
              <ChipField source='lastName' />
            </SingleFieldList>
          </ReferenceManyField>
        }
        { showScheduleFields &&
          <ArrayField source='tags' label='Tags'>
            <SingleFieldList linkType={ false }>
              <ChipField source='name' clickable={ false } />
            </SingleFieldList>
          </ArrayField>
        }

        { showScheduleFields && //          <NumberField source='overbookLevelMax' label='Overbook' transform={ v => v ? v : null } />
          <SelectField source="overbookLevelMax" label='Max Overbook' choices={ choicesOverbook } textAlign='right' />
        }
        {/*  showScheduleFields &&
            <FunctionField source='overbookSameCount' label='Overbook same' render={ ( r?: RaRecord ) => {
            return 
            }}/>
          */ }
        { showScheduleFields &&
          <BooleanColorField source='overbookSameCount' label='Same Overbook' FalseIcon={ null } textAlign='right' />
        }
        { showScheduleFields &&
          <BooleanColorField source='overbookTagCount' label='Tag Overbook' FalseIcon={ null } textAlign='right' />
        }
        { showScheduleFields &&
          <NumberField source='sameSpacing.units' label='Same Space' transform={ v => v ? v : null } />
        }
        { showScheduleFields &&
          <NumberField source='tagSpacing.units' label='Tag Space' transform={ v => v ? v : null } />
        }
        { showScheduleFields &&
          <NumberField source='levelSpacing.0.units' label='Overbook Space' transform={ v => v ? v : null } />
        }
        <TimestampsField label="Updated" source="updatedAt" />
        <EditButton label={ '' } />
      </Datagrid>
    </List >
  );
}

export const AppointmentTypeShow: FC<Childless<ShowProps>> = props => {
  const showScheduleFields = useShowScheduleFields();
  const unitMinutes = useUnitMinutes();

  const choicesDuration = range( 0, 10 ).map( id => ( { id, name: `${ id * unitMinutes }` } ) );

  return (
    <Show  >
      <SimpleShowLayoutWithLocation basePath='appointment' >
        <TextField source="comment" />

        { showScheduleFields &&
          <TextField source="label" label='Patient label (override)' />
        }
        { showScheduleFields &&   // TODO make this a SelectField display units * unitMinutes
          <NumberField source='units' />
        }
        { showScheduleFields &&
          <SelectField source='units' label='Duration' choices={ choicesDuration } textAlign='right' />
        }
        { showScheduleFields &&
          <BooleanColorField source='newPatient' />
        }

        { showScheduleFields &&
          <ArrayField label="Available times by day" source="dayAvailableTimes"  >
            <Datagrid bulkActionButtons={ false } empty={ <Typography>No available days/times</Typography> } >
              <SelectField source='weekday' label='Day' choices={ choices.weekday } />
              <TimeStringPeriodField label='Period' />
            </Datagrid>
          </ArrayField>
        }
        { showScheduleFields &&
          <NumberField source='overbookLevelMax' />
        }
        { showScheduleFields &&
          <NumberField source='sameSpacing.units' />
        }
        { showScheduleFields &&
          <BooleanColorField source='sameSpacing.allLocations' />
        }
        { showScheduleFields &&
          <BooleanColorField source='sameSpacing.allPractitioners' />
        }
        { showScheduleFields &&
          <NumberField source='overbookSameCount' />
        }

        { showScheduleFields &&
          <TextField source="tagSpacing.tag" />
        }
        { showScheduleFields &&
          <NumberField source='tagSpacing.units' />
        }
        { showScheduleFields &&
          <BooleanColorField source='tagSpacing.allLocations' />
        }
        { showScheduleFields &&
          <BooleanColorField source='tagSpacing.allPractitioners' />
        }
        { showScheduleFields &&
          <NumberField source='overbookTagCount' />
        }

        { showScheduleFields &&
          <ArrayField source="levelSpacing"  >
            <Datagrid bulkActionButtons={ false } empty={ <Typography>No available days/times</Typography> } >
              <SelectField source="level" choices={ choicesOverbook } />
              <NumberField source='units' />
              <BooleanColorField source='allLocations' />
              <BooleanColorField source='allPractitioners' />
            </Datagrid>
          </ArrayField>
        }

        { showScheduleFields &&
          <ArrayField source="tags"  >
            <SingleFieldList linkType={ false }>
              <ChipField source='name' clickable={ false } />
            </SingleFieldList>
          </ArrayField>
        }
      </SimpleShowLayoutWithLocation>
    </Show>
  );
}

const CustomToolbar: FC<ToolbarProps> = () => {
  // const { saving } = props;
  const { isValid } = useFormState();

  return (
    <Toolbar>
      <SaveButton
        invalid={ !isValid }
      // saving={ saving }
      />
    </Toolbar>
  );
}



//   sameSpacing?: {  // codeBuffer
//     units: number;
//     allLocations?: boolean;
//     allPractitioners?: boolean;  // sameBufferAll
//   };
//  
//   tagSpacing?: {         // tagBuffer
//     tag: string;
//     units: number;
//     allLocations?: boolean;
//     allPractitioners?: boolean;
//   };

const AppointmentTypeForm: FC<Omit<SimpleFormProps, 'children'>> = props => {
  const showScheduleFields = useShowScheduleFields();

  return (
    <SimpleFormWithLocation basePath='appointment' sanitizeEmptyValues toolbar={ <CustomToolbar /> }  { ...props }    >
      <TextInput source="comment" />
      { showScheduleFields &&
        <>
          <TextInput source="label" label='Patient label (override)' fullWidth={ false } />

          <NumberInput source='units' fullWidth={ false } min={ 1 } step={ 1 } />

          <BooleanInput source='newPatient' label='New patient (only)' fullWidth={ false } />

          { showScheduleFields &&
            <ArrayInput label='Available times by day' source="dayAvailableTimes">
              <SimpleFormIterator disableReordering inline>
                <SelectInput source='weekday' label='Day' choices={ choices.weekday } required />
                <TimeInput source='from' parse={ parseBaseTimeInput } format={ formatBaseTimeInput } required defaultValue={ parseBaseTimeInput( '08:00' ) } />
                <TimeInput source='to' parse={ parseBaseTimeInput } format={ formatBaseTimeInput } required defaultValue={ parseBaseTimeInput( '17:00' ) } />
              </SimpleFormIterator>
            </ArrayInput>
          }


          <Box
            sx={ {
              border: '1px gray solid',
              padding: '1rem',
              margin: '1rem',
            } }>
            <NumberInput source='sameSpacing.units' fullWidth={ false } min={ 0 } step={ 1 } />
            <BooleanInput source='sameSpacing.allLocations' fullWidth={ false } />
            <BooleanInput source='sameSpacing.allPractitioners' fullWidth={ false } />
            <NumberInput source='overbookSameCount' fullWidth={ false } min={ 0 } step={ 1 } />
          </Box>

          <Box
            sx={ {
              border: '1px gray solid',
              padding: '1rem',
              margin: '1rem',
            } }>

            <ArrayInput source='tags' >
              <SimpleFormIterator disableReordering inline>
                <TextInput source='name' fullWidth={ false } />
              </SimpleFormIterator>
            </ArrayInput>


            <TextInput source="tagSpacing.tag" fullWidth={ false } />
            <NumberInput source='tagSpacing.units' fullWidth={ false } min={ 0 } step={ 1 } />
            <BooleanInput source='tagSpacing.allLocations' fullWidth={ false } />
            <BooleanInput source='tagSpacing.allPractitioners' fullWidth={ false } />
            <NumberInput source='overbookTagCount' fullWidth={ false } min={ 0 } step={ 1 } />
          </Box>

          <Box
            sx={ {
              border: '1px gray solid',
              padding: '1rem',
              margin: '1rem',
            } }>

            <ArrayInput source='levelSpacing' >
              <SimpleFormIterator disableReordering inline>
                <SelectInput source="level" choices={ choicesOverbook.slice( 1 ) } fullWidth={ false } validate={ required() } />
                <NumberInput source='units' fullWidth={ false } min={ 0 } step={ 1 } />
                <BooleanInput source='allLocations' fullWidth={ false } />
                <BooleanInput source='allPractitioners' fullWidth={ false } />
              </SimpleFormIterator>
            </ArrayInput>

            <SelectInput source="overbookLevelMax" choices={ choicesOverbook.slice( 1 ) } fullWidth={ false } />
          </Box>

        </>
      }
    </SimpleFormWithLocation >
  );
}

export const AppointmentTypeEdit: FC<EditProps> = props => (
  <Edit
    redirect='list'
    mutationMode='pessimistic'
    { ...props }
  >
    <AppointmentTypeForm />
  </Edit>
);
