/*eslint @typescript-eslint/no-unused-vars: 'warn'*/
import { Box, CircularProgress, Typography, useMediaQuery, useTheme } from '@mui/material';
import { Admin, Layout, LayoutProps } from '@react-admin/ra-enterprise';
import { AppLocationContext } from '@react-admin/ra-navigation';
import { Resource } from '@react-admin/ra-rbac';
import { ReactQueryDevtools } from '@tanstack/react-query-devtools';
import moment from 'moment-timezone';
import { FC, ReactElement, useMemo } from 'react';
import { CustomRoutes } from 'react-admin';
import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import * as reactRouterDom from 'react-router-dom';
import { Route } from 'react-router-dom';
import { SuperTokensWrapper } from 'supertokens-auth-react';
import { EmailPasswordPreBuiltUI } from 'supertokens-auth-react/recipe/emailpassword/prebuiltui';
import { EmailVerificationPreBuiltUI } from 'supertokens-auth-react/recipe/emailverification/prebuiltui';
import { SessionAuth, useSessionContext } from 'supertokens-auth-react/recipe/session/index.js';
import { getSuperTokensRoutesForReactRouterDom } from 'supertokens-auth-react/ui';
import { setLicenseKey as setSurveyLicenseKey } from 'survey-core';
import { ActionLogIcon, ActionLogList } from './ActionLogs';
import { Admin as AdminMenu } from './Admin';
import { Alerts } from './Alerts';
import { AlertTemplateCreate, AlertTemplateEdit, AlertTemplateIcon, AlertTemplateShow } from './AlertTemplates';
import { Anomalies } from './Anomalies';
import { AnomalyReportDefinitionEdit, AnomalyReportDefinitionIcon, AnomalyReportDefinitionShow } from './AnomalyReportDefinitions';
import { AnomalyReportEmailEdit, AnomalyReportEmailIcon, AnomalyReportEmailShow } from './AnomalyReportEmails';
import { AnomalyReportIcon, AnomalyReportShow } from './AnomalyReports';
import { AppFooter } from './AppFooter';
import { Appointment } from './Appointment';
import { AppointmentIcon, AppointmentList, AppointmentShow } from './Appointments';
import { AppointmentTypePackageEdit, AppointmentTypePackageIcon, AppointmentTypePackageList } from './AppointmentTypePackages';
import { AppointmentTypeEdit, AppointmentTypeIcon, AppointmentTypeList, AppointmentTypeShow } from './AppointmentTypes';
import { AppointmentUpdateTextEdit, AppointmentUpdateTextIcon, AppointmentUpdateTextList } from './AppointmentUpdateTexts';
import { AssetCreate, AssetEdit, AssetIcon, AssetShow } from './Assets';
import { MyAuthPage, SupertokensUiCustomization } from './AuthPage';
import { CalendarEventIcon, CalendarEventList, CalendarEventShow } from './CalendarEvents';
import { CalendarPackageEdit, CalendarPackageIcon, CalendarPackageList, CalendarPackageShow } from './CalendarPackages';
import { ConfigEdit, ConfigIcon, ConfigList } from './Configs';
import { CustomAppBar } from './CustomAppBar';
import { Dashboard } from './Dashboard';
import { DashboardAlertIcon } from './DashboardAlerts';
import { dataProvider, queryClient } from './DataProvider';
import { EmrEventTypeEdit, EmrEventTypeIcon, EmrEventTypeShow } from './EmrEventTypes';
import { EvaluatorCreate, EvaluatorEdit, EvaluatorIcon, EvaluatorList } from './Evaluators';
import { EventIcon, EventList, EventShow } from './Events';
import { FeedbackMessageIcon, FeedbackMessageList } from './FeedbackMessages';
import { FileCreate, FileEdit, FileIcon, FileList, FileShow } from './Files';
import { Flow } from './Flow';
import { FlowIcon, FlowList, FlowShow } from './Flows';
import { FormDefinitionCreate, FormDefinitionEdit, FormDefinitionIcon, FormDefinitionList, FormDefinitionShow } from './FormDefinitions';
import { FormSubmissionIcon, FormSubmissionList, FormSubmissionShow } from './FormSubmissions';
import { GoLive } from './GoLive';
import { HtmlAssemblyEdit, HtmlAssemblyIcon, HtmlAssemblyList, HtmlAssemblyShow } from './HtmlAssemblies';
import { HtmlComponentCreate, HtmlComponentEdit, HtmlComponentIcon, HtmlComponentList, HtmlComponentShow } from './HtmlComponents';
import { HtmlEmailTitleEdit, HtmlEmailTitleIcon, HtmlEmailTitleList, HtmlEmailTitleShow } from './HtmlEmailTitles';
import { i18nProvider } from './i18nProvider';
import { InboundEmailIcon, InboundEmailList } from './InboundEmails';
import { IncomingTextChat, IncomingTextIcon, IncomingTextList } from './IncomingText';
import { LanguageIcon, LanguageList } from './Languages';
import { LocationCreate, LocationEdit, LocationIcon, LocationShow } from './Locations';
import { MyMenu } from './Menu';
import { MessageConfigEdit } from './MessageConfigs';
import { MessageTemplateEdit, MessageTemplateIcon, MessageTemplateList, MessageTemplateShow } from './MessageTemplates';
import { Messaging } from './Messaging';
import { MyBreadcrumb } from './MyBreadcrumb';
import { Organization } from './Organization';
import { OrganizationEdit, OrganizationIcon, OrganizationShow } from './Organizations';
import { OutboundAlertCreate, OutboundAlertEdit, OutboundAlertIcon, OutboundAlertShow } from './OutboundAlerts';
import { OutboundMessageIcon, OutboundMessageList, OutboundMessageShow } from './OutboundMessages';
import { Patient } from './Patient';
import { PatientIcon, PatientList, PatientShow } from './Patients';
import { PractitionerEdit, PractitionerIcon, PractitionerShow } from './Practitioners';
import { ReminderIcon, ReminderList } from './Reminders';
import { ReminderTemplateListEdit, ReminderTemplateListIcon, ReminderTemplateListList, ReminderTemplateListShow } from './ReminderTemplateLists';
import { TryReplacementArgs } from './ReplacementArgsInput';
import { Reputation } from './Reputation';
import { ReputationClickIcon, ReputationClickList } from './ReputationClicks';
import { ReputationReferralIcon, ReputationReferralList } from './ReputationReferrals';
import { ReputationReportScheduleCreate, ReputationReportScheduleEdit, ReputationReportSchedulesIcon } from './ReputationReportSchedules';
import { ReputationResponseTemplateCreate, ReputationResponseTemplateEdit, ReputationResponseTemplateList, ReputationResponseTemplateShow } from './ReputationResponseTemplates';
import { ReputationVisualizationCreate, ReputationVisualizationEdit, ReputationVisualizationIcon, ReputationVisualizationShow } from './ReputationVisualizations';
import { Reviews } from './Reviews';
import { Schedule, Schedules } from './Schedule';
import { SnippetEdit, SnippetIcon, SnippetList, SnippetShow } from './Snippets';
import { StateIcon, StateList, StateShow } from './States';
import { SupertokensProvider } from './SupertokensProvider';
import { SurveyCreatorExample } from './SurveyCreatorExample';
import { System } from './System';
import { TagIcon, TagShow } from './Tags';
import { TelecomEditor } from './TelecomEditor';
import { Text } from './Text';
import { CustomTextCreate, CustomTextEdit, CustomTextList, CustomTextShow, TextEdit, TextIcon, TextList, TextShow } from './Texts';
import { darkTheme, lightTheme } from './theme';
import { TwimlEdit, TwimlIcon, TwimlList, TwimlShow } from './Twimls';
import { UserPreferenceProvider } from './UserPreferences';
import { UserCreate, UserEdit, UserIcon, UserList, UserShow } from './Users';
// import { AuthOverride } from './AuthSupervisor';


setSurveyLicenseKey( 'ZjY4YjAxMzAtMGFjZC00MmQ2LTg5NmQtMmMxYWYyMzg0YzY0OzE9MjAyNS0wMS0xNywyPTIwMjUtMDEtMTcsND0yMDI1LTAxLTE3' );

const { REACT_APP_ENABLE_DEVTOOLS: showDevtools } = process.env;

const DebugMyBreadcrumb: FC = props => {
  // const [ appLocation ] = useAppLocationState();
  // const always = false;
  return (
    <>
      <MyBreadcrumb { ...props } />
      { /* ( always || appLocation.path.match( /^admin\.(text|message)/ ) ) &&
        <Typography color='gray' fontSize='0.8rem'>{ appLocation.path }</Typography>
      */ }
    </>
  );

};

export const AccessDenied: FC = () => {

  return (
    <Typography>
      Access denied
    </Typography>
  );

}

const MyCustomLayout: FC<LayoutProps> = ( props ) => {
  const { children, ...rest } = props;
  const sessionContext = useSessionContext();
  const theme = useTheme();
  const isXSmall = useMediaQuery( theme.breakpoints.down( 'sm' ) );

  if( sessionContext.loading ) {
    return <CircularProgress />;
  }

  if( !sessionContext.doesSessionExist ) {
    return <MyAuthPage />
  }

  return (
    <SessionAuth >
      <AppLocationContext>
        <UserPreferenceProvider>
          <Box
            sx={ {
              '& .RaLayout-contentWithSidebar .RaLayout-content': {
                paddingBottom: '2rem', // adjust for AppFooter 
              },
              '& .RaSolarLayout-contentWithSidebar .RaLayout-content': {
                paddingBottom: '2rem', // adjust for AppFooter 
              },
              '& .RaSolarLayout-contentWithSidebar': {
                paddingTop: '2.7rem', // adjust for AppBar 
              },
              '& #main-content': {
                paddingBottom: isXSmall ? '14rem' : '10rem',
              },
            } }
          >
            <Layout
              { ...rest }
              appBar={ CustomAppBar }

              breadcrumb={ DebugMyBreadcrumb }
              menu={ MyMenu }
            >
              { children }
            </Layout>
            <AppFooter />
            { showDevtools && <ReactQueryDevtools initialIsOpen={ false } buttonPosition='bottom-left' /> }
          </Box >
        </UserPreferenceProvider>
      </AppLocationContext>
    </SessionAuth >
  );
}



const Loading = (): ReactElement => ( <Typography>Loading...</Typography> ); // mui/theme conflict wa

export const App = () => {
  const authProvider = useMemo( () => new SupertokensProvider(), [] );

  return (
    <SuperTokensWrapper>
      <SupertokensUiCustomization>
        <DndProvider backend={ HTML5Backend } debugMode={ true } >
          <Admin
            title='Analog Concierge Admin'
            queryClient={ queryClient }
            authProvider={ authProvider }
            dataProvider={ dataProvider }
            requireAuth
            theme={ lightTheme }
            darkTheme={ darkTheme }
            layout={ MyCustomLayout }
            dashboard={ Dashboard }
            loading={ Loading }
            i18nProvider={ i18nProvider }
            disableTelemetry
            loginPage={ <MyAuthPage /> }
          >
            <CustomRoutes noLayout>
              {
                getSuperTokensRoutesForReactRouterDom(
                  reactRouterDom,
                  [ EmailPasswordPreBuiltUI, EmailVerificationPreBuiltUI ]
                )
              }
              <Route path='/auth' element={ <MyAuthPage /> } />
              <Route path='/auth/' element={ <MyAuthPage /> } />
              <Route path='/login' element={ <MyAuthPage /> } />
            </CustomRoutes>

            <CustomRoutes>
              <Route path='/contacteditor' element={ <TelecomEditor /> } />
              <Route path='/broadcasts' element={ <Alerts /> } >
                <Route path='*' element={ <Alerts /> } />
              </Route>
              <Route path='/organization' element={ <Organization /> } >
                <Route path='*' element={ <Organization /> } />
              </Route>
              <Route path='/appointment' element={ <Appointment /> } >
                <Route path='*' element={ <Appointment /> } />
              </Route>
              <Route path='/patient' element={ <Patient /> } >
                <Route path='*' element={ <Patient /> } />
              </Route>
              <Route path='/messaging' element={ <Messaging /> } >
                <Route path='*' element={ <Messaging /> } />
              </Route>
              <Route path='/reputation' element={ <Reputation /> } >
                <Route path='*' element={ <Reputation /> } />
              </Route>
              <Route path='/reviews' element={ <Reviews /> } >
                <Route path='*' element={ <Reviews /> } />
              </Route>
              <Route path='/anomalies' element={ <Anomalies /> } >
                <Route path='*' element={ <Anomalies /> } />
              </Route>
              <Route path='/text' element={ <Text /> } >
                <Route path='*' element={ <Text /> } />
              </Route>

              { /* Some of the magic behind the customtexts setup */ }
              <Route path='/customtexts/create' element={ <CustomTextCreate /> } />
              <Route path='/customtexts/:id/show' element={ <CustomTextShow /> } />
              <Route path='/customtexts/:id' element={ <CustomTextEdit /> } />
              <Route path='/customtexts' element={ <CustomTextList /> } />


              <Route path='/admin' element={ <AdminMenu /> } />
              <Route path='/admin/system' element={ <System /> } />
              <Route path='/admin/system/:action' element={ <System /> } />
              <Route path='/admin/golive' element={ <GoLive /> } />

              {/* <Route path='/aic' element={ <AuthOverride auth='aic' /> } /> */ }
              {/* <Route path='/emr' element={ <AuthOverride auth='emr' /> } /> */ }

              {/*  <Route path='/config' element={ <ConfigurationPage /> } /> */ }

              <Route path='/try' element={ <TryReplacementArgs /> } />
              <Route path='/survey' element={ <SurveyCreatorExample /> } />
              <Route path='/admin/flow' element={ <Flow /> } />
              <Route path='/admin/schedule' element={ <Schedules /> } />
              <Route path='/admin/schedule/:id' element={ <Schedule /> } />
            </CustomRoutes>

            <Resource name='outboundalerts'
              options={ {
                label: 'Broadcasts',
              } }
              // list={ OutboundAlertList }
              show={ OutboundAlertShow }
              create={ OutboundAlertCreate }
              edit={ OutboundAlertEdit }
              icon={ OutboundAlertIcon }
              recordRepresentation='name'
            />

            { /* Combine into Anomalies */ }
            <Resource name='anomalyreports'
              options={ {
                label: 'Anomaly Reports',
                // isAdmin: true,
              } }
              // list={ AnomalyReportList }
              show={ AnomalyReportShow }
              icon={ AnomalyReportIcon }
              recordRepresentation={ ( record ) => `${ record.reportIntervalType } ${ record.reportType } - ${ record.humanAppointmentStopTime }` }
            />

            <Resource name='anomalyreportdefinitions'
              options={ {
                label: 'Anomaly Report Definitions',
                isAdmin: true,
              } }
              // list={ AnomalyReportDefinitionList }
              show={ AnomalyReportDefinitionShow }
              edit={ AnomalyReportDefinitionEdit }
              icon={ AnomalyReportDefinitionIcon }
            />

            <Resource name='anomalyreportemails'
              options={ {
                label: 'Report Emails',
                // isAdmin: true,
              } }
              // list={ AnomalyReportEmailList }
              show={ AnomalyReportEmailShow }
              edit={ AnomalyReportEmailEdit }
              icon={ AnomalyReportEmailIcon }
              recordRepresentation='id'
            />


            <Resource name='appointments'
              list={ AppointmentList }
              show={ AppointmentShow }
              // edit={ AppointmentEdit }
              icon={ AppointmentIcon }
              // recordRepresentation={ ( r ) => `${ new Date( r.startTime ).toLocaleString() } ${ r.patient }` }
              recordRepresentation={ ( r ) => `${ moment( r.startTime ).format( 'MMM D - h:mm a' ) } ${ r.patient }` }
            // recordRepresentation={ <AppointmentRepresentation /> }
            />

            { /* Combine into Patient */ }

            <Resource name='recipients'
              list={ PatientList }
              show={ PatientShow }
              icon={ PatientIcon }
              recordRepresentation='fullName'
            />

            <Resource name='emreventtypes'
              options={ {
                label: 'Update Events',
              } }
              show={ EmrEventTypeShow }
              edit={ EmrEventTypeEdit }
              icon={ EmrEventTypeIcon }
              recordRepresentation='name'
            />


            { /* Combine into Organization */ }

            <Resource name='practitioners'
              // list={ PractitionerList }
              show={ PractitionerShow }
              edit={ PractitionerEdit }
              icon={ PractitionerIcon }
              recordRepresentation='fullName'
            />
            <Resource name='locations'
              //list={ LocationList }
              show={ LocationShow } edit={ LocationEdit } icon={ LocationIcon }
              create={ LocationCreate }
              recordRepresentation='name'
            />
            <Resource name='organizations'
              // list={ OrganizationList }
              edit={ OrganizationEdit } show={ OrganizationShow } icon={ OrganizationIcon }
              recordRepresentation='name'
            />

            <Resource name='dashboardalerts'
              options={ {
                // isAdmin: true,
                label: 'EMR Setup', // 'Setup Alerts',
              } }
              // list={ DashboardAlertList }
              icon={ DashboardAlertIcon }

            />

            <Resource name='alerttemplates'
              options={ {
                label: 'Alert Templates',
                // isAdmin: true,
              } }
              // list={ AlertTemplateList }
              show={ AlertTemplateShow }
              create={ AlertTemplateCreate }
              edit={ AlertTemplateEdit }
              icon={ AlertTemplateIcon }
              recordRepresentation='name'
            />

            <Resource name='appointmentupdatetexts'
              options={ {
                label: 'Appointment Update Text',
                isAdmin: true,
              } }
              list={ AppointmentUpdateTextList }
              edit={ AppointmentUpdateTextEdit }
              icon={ AppointmentUpdateTextIcon }
              recordRepresentation='id'
            />,

            <Resource name='htmlcomponents'
              options={ {
                label: 'Email Components',
                isAdmin: true,
              } }
              list={ HtmlComponentList }
              show={ HtmlComponentShow }
              edit={ HtmlComponentEdit }
              create={ HtmlComponentCreate }
              icon={ HtmlComponentIcon }
              recordRepresentation='id'
            />

            <Resource name='htmlassemblies'
              options={ {
                label: 'Email Assemblies',
                isAdmin: true,
              } }
              list={ HtmlAssemblyList }
              show={ HtmlAssemblyShow }
              edit={ HtmlAssemblyEdit }
              icon={ HtmlAssemblyIcon }
              recordRepresentation='id'
            />

            <Resource name='htmlemailtitles'
              options={ {
                label: 'Email Titles',
                isAdmin: true,
              } }
              list={ HtmlEmailTitleList }
              show={ HtmlEmailTitleShow }
              edit={ HtmlEmailTitleEdit }
              icon={ HtmlEmailTitleIcon }
              recordRepresentation='id'
            />

            <Resource name='languages'
              options={ {
                label: 'Languages',
                isAdmin: true,
              } }
              list={ LanguageList }
              icon={ LanguageIcon }
              recordRepresentation='name'
            />

            <Resource name='texts'
              options={ {
                label: 'Texts',
                isAdmin: true,
              } }
              list={ TextList }
              show={ TextShow }
              edit={ TextEdit }
              create={ CustomTextCreate }
              icon={ TextIcon }
              recordRepresentation='id'
            />

            <Resource name='snippets'
              options={ {
                isAdmin: true,
              } }
              list={ SnippetList }
              show={ SnippetShow }
              edit={ SnippetEdit }
              icon={ SnippetIcon }
              recordRepresentation='id'
            />

            <Resource name='configs'
              options={ {
                isAdmin: true,
              } }
              list={ ConfigList }
              edit={ ConfigEdit }
              icon={ ConfigIcon }
              recordRepresentation='id'
            />

            <Resource name='twimls'
              options={ {
                label: 'TwiML',
                isAdmin: true,
              } }
              list={ TwimlList }
              show={ TwimlShow }
              edit={ TwimlEdit }
              icon={ TwimlIcon }
              recordRepresentation='id'
            />

            <Resource name='users'
              options={ {
                label: 'Users',
                isAdmin: true,
              } }
              list={ UserList }
              show={ UserShow }
              edit={ UserEdit }
              create={ UserCreate }
              icon={ UserIcon }
              recordRepresentation='name'
            />

            <Resource name='evaluators'
              options={ {
                label: 'Evaluators',
                isAdmin: true,
              } }
              list={ EvaluatorList }
              edit={ EvaluatorEdit }
              create={ EvaluatorCreate }
              icon={ EvaluatorIcon }
              recordRepresentation='name'
            />

            <Resource name='files'
              options={ {
                label: 'Files',
                isAdmin: true,
                hide: true,
              } }
              icon={ FileIcon }
              show={ FileShow }
              edit={ FileEdit }
              list={ FileList }
              create={ FileCreate }
              recordRepresentation='name'
            />

            <Resource name='messageconfigs'
              options={ {
                label: 'Message Configs',
                isAdmin: true,
              } }
              edit={ MessageConfigEdit }
              recordRepresentation='id'
            />

            <Resource name='messagetemplates'
              options={ {
                label: 'Message Templates',
                isAdmin: true,
              } }
              list={ MessageTemplateList }
              show={ MessageTemplateShow }
              edit={ MessageTemplateEdit }
              icon={ MessageTemplateIcon }
              recordRepresentation='id'
            />

            <Resource name='remindertemplatelists'
              options={ {
                label: 'Reminder Template Lists',
                isAdmin: true,
              } }
              list={ ReminderTemplateListList }
              show={ ReminderTemplateListShow }
              edit={ ReminderTemplateListEdit }
              icon={ ReminderTemplateListIcon }
              recordRepresentation='id'
            />

            <Resource name='appointmenttypes'
              options={ {
                label: 'Appointment Types',
                isAdmin: true,
              } }
              list={ AppointmentTypeList }
              edit={ AppointmentTypeEdit }
              show={ AppointmentTypeShow }
              icon={ AppointmentTypeIcon }
              recordRepresentation='name'
            />

            <Resource name='appointmenttypepackages'
              options={ {
                label: 'Appointment Type Packages',
                isAdmin: true,
              } }
              list={ AppointmentTypePackageList }
              edit={ AppointmentTypePackageEdit }
              icon={ AppointmentTypePackageIcon }
              recordRepresentation='name'
            />

            <Resource name='calendarpackages'
              options={ {
                label: 'Calendar Packages',
                isAdmin: true,
              } }
              list={ CalendarPackageList }
              edit={ CalendarPackageEdit }
              show={ CalendarPackageShow }
              icon={ CalendarPackageIcon }
              recordRepresentation='name'
            />

            <Resource name='reminders'
              options={ {
                label: 'Scheduled Messages',
                // isAdmin: true,
              } }
              list={ ReminderList }
              icon={ ReminderIcon }
            />

            <Resource name='outboundmessages'
              options={ {
                label: 'Sent Messages',
                // isAdmin: true,
              } }
              list={ OutboundMessageList }
              show={ OutboundMessageShow }
              icon={ OutboundMessageIcon }
              recordRepresentation={ ( r ) => `${ r.recipientName } ${ moment( r.postedDate ).format( 'MMM D - h:mm a' ) } by ${ r.transmitMethod }` }
            />

            <Resource name='calendarevents'
              options={ {
                label: 'Calendar Events',
                // isAdmin: true,
              } }
              list={ CalendarEventList }
              show={ CalendarEventShow }
              icon={ CalendarEventIcon }
              recordRepresentation={ ( r ) => `${ r.description } ${ moment( r.start ).format( 'MMM D - h:mm a' ) }` }
            />

            <Resource
              name='reputationplatforms'
              recordRepresentation='name'
            />
            <Resource name='reputationprofiles' />

            <Resource name='reputationclicks'
              options={ {
                label: 'Reputation Clicks',
                isAdmin: true,
              } }
              list={ ReputationClickList }
              icon={ ReputationClickIcon }
            />

            <Resource name='reputationreferrals'
              options={ {
                label: 'Reputation Referrals',
                isAdmin: true,
              } }
              list={ ReputationReferralList }
              icon={ ReputationReferralIcon }
            />

            <Resource name='reputationresponsetemplates'
              options={ {
                label: 'Response Templates'
              } }
              list={ ReputationResponseTemplateList }
              show={ ReputationResponseTemplateShow }
              create={ ReputationResponseTemplateCreate }
              edit={ ReputationResponseTemplateEdit }
              recordRepresentation='title'
            />

            <Resource name='feedbackmessages'
              options={ {
                label: 'Reputation Messages',
                isAdmin: true,
              } }
              list={ FeedbackMessageList }
              icon={ FeedbackMessageIcon }
            />

            <Resource name='actionlogs'
              options={ {
                label: 'Action Logs',
                isAdmin: true,
              } }
              list={ ActionLogList }
              icon={ ActionLogIcon }
            />

            <Resource name='formdefinitions'
              options={ {
                label: 'Form Definitions',
                isAdmin: true,
              } }
              list={ FormDefinitionList }
              create={ FormDefinitionCreate }
              show={ FormDefinitionShow }
              edit={ FormDefinitionEdit }
              icon={ FormDefinitionIcon }
              recordRepresentation='id'
            />

            <Resource name='formsubmissions'
              options={ {
                label: 'Form Submissions',
                isAdmin: true,
              } }
              list={ FormSubmissionList }
              show={ FormSubmissionShow }
              icon={ FormSubmissionIcon }
              recordRepresentation='id'
            />

            <Resource name='inboundemails'
              options={ {
                label: 'Inbound Emails',
                isAdmin: true,
              } }
              list={ InboundEmailList }
              icon={ InboundEmailIcon }
            />

            <Resource name='inboundtexts'
              list={ IncomingTextList }
              show={ IncomingTextChat }
              icon={ IncomingTextIcon }
              recordRepresentation={ ( r ) => `${ r.senderName } on ${ moment( r.createdAt ).format( 'MMM D @ h:mm a' ) }` }
            />


            <Resource name='reputationreviews' />
            <Resource name='reputationsubjectreviews' />
            <Resource
              name='reputationsubjects'
              recordRepresentation='name'
            />
            <Resource name='reputationsummaries' />

            <Resource name='reputationvisualizations'
              options={ {
                label: 'Reputation Visualizations',
                isAdmin: true,
              } }
              // list={ ReputationVisualizationList }
              create={ ReputationVisualizationCreate }
              edit={ ReputationVisualizationEdit }
              show={ ReputationVisualizationShow }
              icon={ ReputationVisualizationIcon }
              recordRepresentation='title'
            />
            <Resource name='reputationreportschedules'
              options={ {
                label: 'Scheduled Reputation Reports',
                isAdmin: true,
              } }
              // list={ ReputationReportScheduleList }
              edit={ ReputationReportScheduleEdit }
              create={ ReputationReportScheduleCreate }
              icon={ ReputationReportSchedulesIcon }
              recordRepresentation='id'
            />

            <Resource name='assets'
              icon={ AssetIcon }
              show={ AssetShow }
              edit={ AssetEdit }
              create={ AssetCreate }
            />

            <Resource name='tags'
              show={ TagShow }
              icon={ TagIcon }
            />
            <Resource name='images' />

            <Resource name='feedbacks' />
            <Resource name='subscriptionchanges' />
            <Resource name='unsubscribeds' />
            <Resource name='alertrecipients' />
            <Resource name='authlogs' />
            <Resource name='profilelogs' />
            <Resource name='golives' />
            <Resource name='userprefernces' />
            <Resource name='flows'
              recordRepresentation='name'
              icon={ FlowIcon }
              list={ FlowList }
              show={ FlowShow }
              options={ {
                label: 'Flows',
                isAdmin: true,
              } }

            />
            <Resource name='events'
              recordRepresentation='id'
              icon={ EventIcon }
              list={ EventList }
              show={ EventShow }
              options={ {
                label: 'Events',
                isAdmin: true,
              } }

            />
            <Resource name='states'
              recordRepresentation='id'
              icon={ StateIcon }
              list={ StateList }
              show={ StateShow }
              options={ {
                label: 'States',
                isAdmin: true,
              } }

            />

          </Admin >
        </DndProvider>
      </SupertokensUiCustomization>
    </SuperTokensWrapper >
  );
}


export default App;
