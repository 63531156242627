import { SsidChart as LineChartIcon, TableChartOutlined as TableIcon } from '@mui/icons-material';
import { Box, Card, CardContent, CardHeader, IconButton as MuiIconButton, Typography, useTheme } from '@mui/material';
import { GridColType } from '@mui/x-data-grid-premium';
import { Bar, BarDatum } from '@nivo/bar';
import { capitalize } from 'inflection';
import { get, set, sortBy } from 'lodash';
import { FC, useMemo, useState } from 'react';
import { Loading } from 'react-admin';
import { ChartProps, elevation } from './DashboardWidget';
import useColorPalettes from './use-color-palettes';
import usePatterns from './use-patterns';
import useSummary from './use-summary';
import { useUserPreference } from './UserPreferences';
import _ from 'lodash';
import { DashboardTable } from './DashboardDatagrid';
import { DashboardInfo } from './DashboardInfo';

interface LocalizedMessageSentDatum {
  method: string;
  count: string;
  lang: string;
}

export const DashboardLocalizedMessageActivity: FC<ChartProps> = ( { date } ) => {
  const theme = useTheme();
  const isSmall = false;
  const { preferences, isLoading: isLoadingPreferences } = useUserPreference();
  const [ showDatagrid, setShowDatagrid ] = useState( false );
  const defaultDurationDays = 30;
  const cacheKey = 'dashboard-localized-message-activity';
  const keys = [ 'email', 'sms', 'voice' ].map( capitalize );

  const durationDays = useMemo( () => {
    if( isLoadingPreferences || !preferences?.dashboardActivityDays ) return defaultDurationDays;
    return preferences.dashboardActivityDays as number;
  }, [ preferences, isLoadingPreferences ] );

  const summary = useSummary<LocalizedMessageSentDatum[]>( 'localizedMessageActivity', { date, days: durationDays } );

  const data = useMemo( () => {
    const _data: Record<string, Record<string, number | string[]>> = {};
    const displayNames = new Intl.DisplayNames( window.navigator.languages, { type: 'language' } );
    for( const d of summary.data || [] ) {
      if( !d.method || !d.lang ) continue; // safeguard for bad data
      const countKey = `${ d.lang }.${ capitalize( d.method ) }`;
      set( _data, countKey, get( _data, countKey, 0 ) + d.count );
    }
    let rows = sortBy( Object.entries( _data ).map( d => ( {
      ...d[ 1 ],
      lang: displayNames.of( d[ 0 ] ),
      total:  _( d[ 1 ] ).pick( keys ).values().sum().valueOf()
    } ) ), 'total' ) as BarDatum[];
    // console.log( rows );
    if( rows.length == 0 ) {
      // @ts-ignore:2322
      rows = [ { lang: 'No data', 'Sms': 10, total: 10 }, { lang: '' }, { lang: ' ' } ];
    }
    return rows;
  }, [ date, summary.data ] );

  const isEmpty = useMemo( () => data[ 0 ]?.lang === 'No data', [ data ] );

  const maxValue = useMemo( () => Math.pow( 10, Math.ceil( Math.log10( _.max( data.map( d => d.total as number ) ) || 10 ) ) ), [ data ] );
  const ticks = useMemo( () => _.range( 0, Math.log10( maxValue ) + 1 ).map( v => v && 10 ** v ), [ maxValue ] );

  const colorPalettes = useColorPalettes();
  const colors = useMemo( () => colorPalettes.discretePrimary.slice( -keys.length ), [ colorPalettes, keys ] );
  const patterns = usePatterns();

  return (

    <Card
      elevation={ elevation }
      sx={ {
        minHeight: '25em',
        overflowX: 'visible',
        overflow: 'initial',
      } }
    >
      <CardHeader
        title={
          <Box sx={ { display: 'flex' } } >
            <Typography>Localized Message Activity</Typography>
            <DashboardInfo tag='localized-message-ac'>
              <i>Localized Message Activity</i> shows the languages used by all messages and their respective formats over a period of time.
              Use this to see what languages your patients are communicating in.
              Use the <TableIcon fontSize='small' sx={{ verticalAlign: 'bottom' }}/> Data View button to show the numerical data.
            </DashboardInfo>
            <Box sx={ { flexGrow: 1 } } />
            <MuiIconButton
              sx={ {
                // paddingTop: 0, paddingBottom: 0,
                marginTop: '-8px',
                marginBottom: '-8px',
              } }
              onClick={ () => setShowDatagrid( !showDatagrid ) }
            >
              { showDatagrid ? <LineChartIcon /> : <TableIcon /> }
            </MuiIconButton>
          </Box>
        }
        titleTypographyProps={ {
          sx: {
            fontSize: '1.2rem',
            fontWeight: 400,
          }
        } }
        subheader={ date ? `${ durationDays } Days Ending ${ date.format( 'MMM D' ) }` : `Previous ${ durationDays } Days` }
        subheaderTypographyProps={ {
          sx: {
            fontSize: '0.9rem',
          }
        } }
      />
      <CardContent
        sx={ {
          overflowX: 'visible',
        } }
      >
        { summary.isLoading
          ? <Box>
            <Loading
              loadingPrimary=''
              loadingSecondary=''
              sx={ {
                '@media (min-width: 0)': {
                  marginTop: 0,
                  marginBottom: 7,
                  height: '100%',
                  width: 500,
                  minHeight: 400,
                }
              } }
            />
          </Box>

          :
          <Box
            sx={ {
              // position: 'relative',
            } }
          >
            { showDatagrid
              ? (
                <DashboardTable
                  rows={ ( data.reverse() ).map( ( d, id ) => ( { id, ...d } ) ) }
                  columns={ [
                    { field: 'lang', headerName: 'Language' },
                    { field: 'total', headerName: 'Total', type: 'number' as GridColType, sortable: true },
                    ...keys,
                  ] }
                  exportFileName={ cacheKey }
                />
              )
              : <>

                <Bar
                  width={ 500 }
                  height={ 400 }
                  data={ data }
                  indexBy='lang'
                  keys={ keys }
                  margin={ { top: 20, right: 80, bottom: 55, left: 150 } }
                  padding={ 0.3 }
                  groupMode='grouped'
                  layout='horizontal'
                  valueScale={ { type: 'symlog' } }
                  indexScale={ { type: 'band', round: true } }
                  gridXValues={ ticks }

                  isInteractive={ !isEmpty }

                  maxValue={ maxValue }

                  theme={ {
                    background: theme.palette?.background?.default,
                    axis: {
                      legend: { text: { fontSize: isSmall ? 16 : 18 } },
                      ticks: { text: { fontSize: isSmall ? 0 : 14 } },
                    },
                    labels: { text: { fill: '#333' } },
                    legends: { text: { fontSize: 14 } },
                    text: { fill: theme.palette?.mode === 'dark' ? '#ccc' : '#333' },
                    tooltip: {
                      container: {
                        borderRadius: '8px',
                        border: `0.5px solid ${ theme.palette?.grey?.A400 ?? 'grey' }`,
                        boxShadow: `${ theme.palette?.grey?.A400 ?? 'grey' } 0px 2px 2px`,
                        backgroundColor: theme.palette?.background?.default,
                        color: theme.palette?.mode === 'dark' ? '#ccc' : '#333',
                        padding: '5px 9px',
                      },
                    },
                  } }

                  colors={ isEmpty ? [ theme.palette?.mode === 'dark' ? '#555' : '#ccc' ] : colors } // { scheme: 'accent', size: keys.length } }
                  defs={ patterns.defs }
                  fill={ patterns.fillByKeys( keys ) }

                  enableLabel={ false }
                  enableGridX={ true }
                  enableGridY={ false }
                  axisTop={ null }
                  axisRight={ null }
                  axisBottom={ {
                    tickSize: 5,
                    tickPadding: 5,
                    tickRotation: 0,
                    tickValues: ticks,
                    legend: 'Count (log)',
                    legendPosition: 'middle',
                    legendOffset: 42,
                  } }
                  axisLeft={ {
                    tickSize: 5,
                    tickPadding: 5,
                    tickRotation: 0,
                    legend: 'Language',
                    legendPosition: 'middle',
                    legendOffset: -140
                  } }
                  legends={ [
                    {
                      dataFrom: 'keys',
                      anchor: 'right',
                      direction: 'column',
                      justify: false,
                      translateX: 90,
                      translateY: -120,
                      itemsSpacing: 4,
                      itemWidth: 80,
                      itemHeight: 20,
                      itemDirection: 'left-to-right',
                      itemOpacity: 0.85,
                      symbolSize: 20,
                      symbolShape: 'square',
                      effects: [
                        {
                          on: 'hover',
                          style: {
                            itemOpacity: 1
                          }
                        }
                      ]
                    }
                  ] }
                />
              </>
            }
          </Box>
        }
      </CardContent>
    </Card>

  );

}

