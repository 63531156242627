import { SaveAlt as ExportIcon } from '@mui/icons-material';
import { Box, Card, CardContent, CardHeader, Tab, Tabs, Typography } from '@mui/material';
import { GridToolbarContainer, GridToolbarExport } from '@mui/x-data-grid-premium';
import { FC, useCallback, useState } from 'react';
import { useDataProvider, useRedirect } from 'react-admin';
import { renderPhoneNumber } from './AwesomePhoneInput';
import { ChartProps, elevation } from './DashboardWidget';
import useSummary from './use-summary';
import { DashboardTable } from './DashboardDatagrid';
import { DashboardInfo } from './DashboardInfo';

interface DashboardUndeliverableDatum {
  _id: string;
  recipientId: string;
  date: string;
  recipientFullName: string;
  value: string;
}

export const DashboardUndeliverables: FC<ChartProps> = () => {
  const redirect = useRedirect();
  const [ tab, setTab ] = useState( 'Emails' );
  const cacheKey = () => `dashboard-undeliverables-${ tab.toLowerCase() }`;
  const dataProvider = useDataProvider();

  const onTabChange = useCallback( ( v: string ) => {
    setTab( v );
    localStorage.setItem( `dashboard-undeliverables-tab`, v );
  }, [] );

  const summary = useSummary<DashboardUndeliverableDatum[]>( `undeliverable${ tab }` );

  const onClickRecipient = async ( id: string ) => {
    const { data: recipient } = await dataProvider.getOne( 'recipients', { id } );
    if( !recipient ) return;
    redirect( '/contacteditor', undefined, undefined, undefined, { recipient } )
  };

  function ExportOnlyToolbar() {
    return (
      <GridToolbarContainer
        sx={ {
          height: '3rem',
          '& > .MuiButton-root': {
            marginTop: '-2rem',
          }
        } }
      >
        <Tabs
          variant='standard'
          value={ tab }
          onChange={ ( _e, v ) => onTabChange( v ) }
          sx={ {
            marginTop: '-2rem',
          } }
        >
          <Tab label='Email' value='Emails' />
          <Tab label='Phone' value='Phones' />
        </Tabs>
        <Box sx={ { flexGrow: 1 } } />
        <GridToolbarExport
          csvOptions={ {
            fileName: cacheKey(),
            utf8WithBom: true,
            allColumns: true,
            // getRowsToExport: getRowsWithoutGroups,
          } }
          excelOptions={ {
            fileName: cacheKey(),
            allColumns: true,
            // getRowsToExport: getRowsWithoutGroups,
            // exceljsPreProcess,
            // exceljsPostProcess,
          } }
          printOptions={ {
            fileName: cacheKey(),
            disableToolbarButton: true,
            hideToolbar: true,
            hideFooter: true,
            includeHeaders: false,
          } }

        />
      </GridToolbarContainer>
    );
  }

  return (
    ( <Card
      elevation={ elevation }
      sx={ {
        minHeight: '25em',
        height: '100%',
        overflowX: 'visible',
        overflow: 'initial',
      } }
    >
      <CardHeader
        title={
          <Box sx={ { display: 'flex' } } >
            <Typography>Undeliverables</Typography>
            <DashboardInfo tag='undeliverables'>
              <i>Undeliverables</i> shows patients who were sent messages by email or phone (voice or TXT)
              that could not be delivered and the last attempted contact date. Export the data using
              the <ExportIcon fontSize='small' sx={{ verticalAlign: 'bottom' }} /> Export button.
            </DashboardInfo>
            <Box sx={ { flexGrow: 1 } } />
          </Box>
        }
        titleTypographyProps={ {
          sx: {
            fontSize: '1.2rem',
            fontWeight: 400,
            // lineHeight: 1.334,
          }
        } }
      // subheader={ date ? `7 Days Starting ${ date.format( 'MMM D' ) }` : 'Next 7 Days' }
      // subheaderTypographyProps={ {
      //   sx: {
      //     fontSize: '0.9rem',
      //   }
      // } }
      />
      <CardContent
        sx={ {
          overflowX: 'visible',
        } }
      >
        <DashboardTable
          rows={ summary.isLoading || !summary.data
            ? []
            : summary.data.map( ( d, id ) => {
              const { recipientId, recipientFullName, date: dateString, value: rawValue } = d;
              const value = tab == 'Phones' ? renderPhoneNumber( rawValue ) : rawValue;
              const date = new Date( dateString ).toISOString().slice( 0, 10 );
              return { id, recipientId, recipientFullName, date, value };
            } ) }
          columns={ [
            { field: 'recipientFullName', headerName: 'Recipient', sortable: true, flex: 1 },
            { field: 'value', headerName: tab == 'Phones' ? 'Phone' : 'Email', sortable: true, flex: 1 },
            { field: 'date', headerName: tab == 'Phones' ? 'Checked' : 'Last message', sortable: true, align: 'right', headerAlign: 'right', flex: 1 },
          ] }
          onRowClick={ params => onClickRecipient( params.row.recipientId ) }
          toolbar={ ExportOnlyToolbar }
          sx={ { marginTop: '-1.2rem' } }
        />
      </CardContent>
    </Card > )
  );

}

