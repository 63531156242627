import { SsidChart as LineChartIcon, TableChartOutlined as TableIcon } from '@mui/icons-material';
import { Box, Card, CardContent, CardHeader, IconButton, Typography, useTheme } from '@mui/material';
import { AxisTick } from '@nivo/axes';
import { Bar, BarDatum, ComputedBarDatum, ComputedDatum } from '@nivo/bar';
import { useCanAccess } from '@react-admin/ra-rbac';
import * as dateFns from 'date-fns';
import _ from 'lodash';
import { FC, useMemo, useState } from 'react';
import { Loading } from 'react-admin';
import { ChartProps, elevation, OnlyNumbers } from './DashboardWidget';
import { useUserPreference } from './UserPreferences';
import useColorPalettes from './use-color-palettes';
import usePatterns from './use-patterns';
import { useOrgDateMarkers } from './use-markers';
import useSummary from './use-summary';
import { DashboardDatagrid } from './DashboardDatagrid';
import { GridColType } from '@mui/x-data-grid-premium';
import { DashboardInfo } from './DashboardInfo';

interface ReviewTrendDatum extends BarDatum {
  date: string;
  md: string;
  ymd: string;
  '1 star': number;
  '2 stars': number;
  '3 stars': number;
  '4 stars': number;
  '5 stars': number;
}

export const DashboardNetPromoterTrend: FC<ChartProps> = ( { date } ) => {
  const theme = useTheme();
  const { canAccess: canAccessSystem } = useCanAccess( { action: 'manage', resource: 'system' } );
  const isSmall = false;
  const { preferences, isLoading: isLoadingPreferences } = useUserPreference();
  const defaultDurationMonths = 6;
  const cacheKey = 'dashboard-review-trend';
  const [ showDatagrid, setShowDatagrid ] = useState( false );

  const durationMonths = useMemo( () => {
    if( isLoadingPreferences || !preferences?.dashboardTrendMonths ) return defaultDurationMonths;
    return preferences.dashboardTrendMonths as number;
  }, [ preferences, isLoadingPreferences ] );

  const summary = useSummary<ReviewTrendDatum[]>( 'reviewTrend', { date, months: durationMonths } );

  const isEmpty = useMemo( () => {
    return !summary.data?.find( datum => {
      for( const key in datum ) {
        if( key == 'id' ) continue;
        if( datum[ key ] ) return true;
      }
      return false;
    } )
  }, [ summary.data ] );
  const keys: ( keyof OnlyNumbers<ReviewTrendDatum> )[] = [ '1 star', '2 stars', '3 stars', '4 stars', '5 stars' ];

  const data = useMemo<BarDatum[]>( () => {
    return summary.data?.map( datum => {
      const total = _( datum ).pick( keys ).values().sum();
      if( !total ) return { ...datum, score: 0 };
      const fives = datum[ '5 stars' ] ?? 0;
      const fours = datum[ '4 stars' ] ?? 0;
      const promoters = fives + fours;
      return {
        ...datum,
        promoters,
        total,
        Score: ( promoters * 100 / total ).toFixed( 1 ),
      }
    } ) || [];
  }, [ summary.data ] )

  const colorPalettes = useColorPalettes();
  const ratingCategories = [
    { minimum: 100, color: colorPalettes.spectrumGreenRed[ 0 ], label: 'Perfect' },
    { minimum: 90, color: colorPalettes.spectrumGreenRed[ 0 ], label: 'Excellent' },
    { minimum: 80, color: colorPalettes.spectrumGreenRed[ 1 ], label: 'Great' },
    { minimum: 50, color: colorPalettes.spectrumGreenRed[ 2 ], label: 'Average' },
    { minimum: 25, color: colorPalettes.spectrumGreenRed[ 3 ], label: 'Poor' },
    { minimum: 0, color: colorPalettes.spectrumGreenRed[ 4 ], label: 'Bad' },
  ];
  const patterns = usePatterns();

  const yMax = useMemo( () => Math.max( ...[ 10, ...data.map( d => parseFloat( d.Score as string ) ) ] ), [ data ] );

  const tickValues = ratingCategories.map( c => c.minimum ).reverse();

  const getColor = ( datum: ComputedDatum<BarDatum> ): string => {
    const value = datum.value || 0;
    const category = ratingCategories.find( c => value >= c.minimum );
    return category?.color || colorPalettes.spectrumGreenRed.reverse()[ 0 ];
  }

  const getLabel = ( datum: ComputedDatum<BarDatum> ): string => {
    const value = datum.value || 0;
    const category = ratingCategories.find( c => value >= c.minimum );
    return category?.label || '';
  }

  const tickMonths = useMemo( () => {
    const skipSize = Math.ceil( data.length / 8 );
    return data.map( d => d.id ).filter( ( _d, i ) => !( i % skipSize ) );
  }, [ data ] );

  const markers = useOrgDateMarkers( {
    date,
    duration: { type: 'months', count: durationMonths },
    formatValue: d => dateFns.format( d, `MMM ''yy` ),
  } );

  return (
    <Card
      elevation={ elevation }
      sx={ {
        minHeight: '25em',
        overflowX: 'visible',
        overflow: 'initial',
      } }
    >
      <CardHeader
        title={
          <Box sx={ { display: 'flex' } } >
            <Typography>NPS Trend</Typography>
            <DashboardInfo tag='nps-trend' placement='bottom-start'>
              <i>NPS Trend</i> shows your Net Promoter Score (NPS). NPS is a valuable metric in healthcare for measuring patient satisfaction and loyalty.
              NPS scores are a sign of the quality of the customer experience delivered and loyalty to the brand.
              Use the <TableIcon fontSize='small' sx={{ verticalAlign: 'bottom' }}/> Data View button to show the numerical data.
            </DashboardInfo>
            <Box sx={ { flexGrow: 1 } } />
            <IconButton
              sx={ {
                marginTop: '-8px',
                marginBottom: '-8px',
              } }
              onClick={ () => setShowDatagrid( !showDatagrid ) }
            >
              { showDatagrid ? <LineChartIcon /> : <TableIcon /> }
            </IconButton>
          </Box>
        }
        titleTypographyProps={ {
          sx: {
            fontSize: '1.2rem',
            fontWeight: 400,
          }
        } }
        subheader={ date ? `${ durationMonths } Months Ending ${ date.format( 'MMM YYYY' ) }` : `Previous ${ durationMonths } Months` }
        subheaderTypographyProps={ {
          sx: {
            fontSize: '0.9rem',
          }
        } }
      />
      <CardContent
        sx={ {
          overflowX: 'visible',
        } }
      >
        { summary.isLoading
          ? <Box>
            <Loading
              loadingPrimary=''
              loadingSecondary=''
              sx={ {
                '@media (min-width: 0)': {
                  marginTop: 0,
                  marginBottom: 7,
                  height: '100%',
                  width: 500,
                  minHeight: 400,
                }
              } }
            />
          </Box>

          :
          <Box
            sx={ {
              position: 'relative',
            } }
          >
            { showDatagrid
              ? (
                <DashboardDatagrid
                  rows={ ( data ).map( ( d, id ) => ( { id, ...d } ) ) }
                  columns={ [
                    'promoters',
                    { field: 'total', headerName: 'Reviews', type: 'number' as GridColType },
                    { field: 'Score', valueFormatter: v => v && `${v}%`, type: 'number' as GridColType },
                  ] }
                  exportFileName={ cacheKey }
                />
              )
              : <>
                <Bar
                  width={ 500 }
                  height={ 400 }
                  data={ isEmpty ? [] : data }
                  keys={ [ 'Score' ] }
                  margin={ { top: 50, right: 50, bottom: 55, left: 75 } }
                  padding={ 0.25 }
                  gridYValues={ tickValues }

                  isInteractive={ !isEmpty }

                  maxValue={ tickValues.find( v => v >= yMax ) || tickValues[ tickValues.length - 1 ] }

                  markers={ markers }

                  tooltipLabel={ getLabel }

                  theme={ {
                    background: theme.palette?.background?.default,
                    axis: {
                      legend: { text: { fontSize: isSmall ? 16 : 18 } },
                      ticks: { text: { fontSize: isSmall ? 0 : 14 } },
                    },
                    labels: { text: { fill: '#333' } },
                    legends: { text: { fontSize: 14 } },
                    text: { fill: theme.palette?.mode === 'dark' ? '#ccc' : '#333' },
                    tooltip: {
                      container: {
                        borderRadius: '8px',
                        border: `0.5px solid ${ theme.palette?.grey?.A400 ?? 'grey' }`,
                        boxShadow: `${ theme.palette?.grey?.A400 ?? 'grey' } 0px 2px 2px`,
                        backgroundColor: theme.palette?.background?.default,
                        color: theme.palette?.mode === 'dark' ? '#ccc' : '#333',
                        padding: '5px 9px',
                      },
                    },
                  } }

                  colors={ isEmpty ? [ theme.palette?.mode === 'dark' ? '#555' : '#ccc' ] : getColor } //{ scheme: colors, size: keys.length } }
                  defs={ patterns.defs }
                  fill={ ratingCategories.map( ( c, i ) => ( {
                    id: `${ ratingCategories.length - i }`,
                    match: ( d: ComputedBarDatum<BarDatum> ) => ( d.data.value || 0 ) >= c.minimum,
                  } ) ) }

                  enableGridX={ false }
                  enableGridY={ true }
                  enableLabel={ false }
                  axisTop={ null }
                  axisRight={ null }
                  axisLeft={ {
                    tickSize: 5,
                    tickPadding: 5,
                    tickRotation: 0,
                    tickValues: tickValues,
                    legend: 'Score (%)',
                    legendPosition: 'middle',
                    legendOffset: -67
                  } }

                  axisBottom={ {
                    tickSize: 5,
                    tickPadding: 5,
                    renderTick: ( props => tickMonths.includes( props.value ) ? <AxisTick {...props}/> : <></> ),
                    legend: 'Month',
                    legendPosition: 'middle',
                    legendOffset: 42,
                  } }
                />
              </>
            }
          </Box>
        }
      </CardContent>
    </Card >

  )
}
